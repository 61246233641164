<app-header-image [backgroundImage]="'assets/images/others/press.jpg'" 
                  [bgImageAnimate]="true" 
                  [contentOffsetToTop]="true"  
                  [title]="'Press'"
                  [desc]="">
</app-header-image>

<div class="px-3">
  <div class="theme-container">
      <mat-card class="main-content-header"> 
          <div class="section pt-1 px-3"> 
              <h1 class="section-title">Awards</h1> 


              <p>We place the utmost importance on producing high-quality services for our customers, and winning an award has never been a goal.</p>
<p>However, there are occasions when the rewards make you happy.</p>

                   
                
              <div fxLayout="row wrap"> 
                  <div fxFlex="100" fxFlex.gt-sm="50" class="py-3"> 
                      <img src="assets/images/award.jpg" alt="about-1" class="mw-100">
                  </div> 
                  <div fxFlex="100" fxFlex.gt-sm="50" class="py-3" [ngClass.gt-sm]="(settings.rtl) ? 'pr-5' : 'pl-5'"> 
                      <h1 class="secondary-font primary-color text-center">Curry Life Award Winner 2022</h1>
                      <h3>To Our Awesome Customers - Thank you !</h3>
                      <p>We're delighted to announce the news that Indian Aroma Restaurant has won the Curry Life Editor's Award for 2022.</p>
<p>It's a prestigious award won after years of hard work.As always, we appreciate our staff's hard work and dedication, the love and support of our friends and family, and our cusomer's support.</p>

                  </div> 
              </div> 
          </div>  
      </mat-card>  
      <app-our-awards></app-our-awards>
  </div> 
</div>   