<!-- <div class="error-area ptb-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="error-content">
                    <img src="https://www.indianaromacaterers.co.uk/assets/img/error.png" alt="image">
                    <h3>Error 404 : Page Not Found</h3>
                    <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                    <div class="btn-box">
                        <a routerLink="/" class="default-btn"><i class="flaticon-history"></i>Go Back<span></span></a>
                        <a routerLink="/" class="default-btn"><i class="flaticon-home"></i>Homepage<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="p-3">  
    <div class="theme-container"> 
    
    <mat-card class="main-content-header p-0 mb-4"> 
    
    <div  fxLayout="column" fxLayoutAlign="center center" class="text-center py-5"> 
        <img src="https://www.indianaromacaterers.co.uk/assets/img/error.png" alt="image">
        <h3>Error 404 : Page Not Found</h3>
        <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
        <p>If the issue persists, Please try again and <a [routerLink]="['/contact']"> contact us.</a></p>  
      <a [routerLink]="['/']" mat-raised-button color="primary" class="mt-3">Go to Homepage</a> 
    </div>  
    
    
    
    </mat-card> 
    
    </div> 
    </div> 