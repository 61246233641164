import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
  public times = [
    { icon: "../../../assets/images/icons/facebook.svg", title: 'Facebook', hours:'LIKE US ON FACEBOOK', link : "https://www.facebook.com/indianaromalondon"   },
    { icon: "../../../assets/images/icons/insta.svg", title: 'Instagram', hours:'FOLLOW US ON INSTAGRAM', link: "https://www.instagram.com/indian_aroma_london/" },   
    { icon: "../../../assets/images/icons/google.svg", title: 'Google', hours:'REVIEW US ON GOOGLE', link: "https://g.page/r/Cclpzi4MChGHEB0/review" }, 
    { icon: "../../../assets/images/icons/youtube (1).svg", title: 'Youtube', hours:'SUBSCRIBE ON YOUTUBE' , link : "https://www.youtube.com/channel/UCc4bUg_a2W-7W_gkYiqEgRg"},
    { icon: "../../../assets/images/icons/twitter.svg", title: 'Twitter', hours:'FOLLOW US ON TWITTER', link : "https://twitter.com/IndianAroma_LDN"   },
    { icon: "../../../assets/images/icons/printest.svg", title: ' Pinterest', hours:'FOLLOW US ON PINTEREST', link : "https://www.pinterest.co.uk/indianaromalondon/"   },
    { icon: "../../../assets/images/icons/footer icons/linkdin.png", title: ' LinkedIn', hours:'FOLLOW US ON LINKEDIN', link : "https://www.linkedin.com/company/indian-aroma-cuisine-limited/"   },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
