<a href="https://wa.me/+447916507777" target="_blank" class="social-icon mr-1"> 
  <img src="../../../assets/images/icons/header icons/Group 23.svg" class="w-100">
</a>
<a href="https://www.facebook.com/indianaromalondon"  target="_blank" class="social-icon mr-1">
  <img src="../../../assets/images/icons/header icons/Group 24.svg" class="w-100">
</a>
<a href="https://www.instagram.com/indian_aroma_london/" target="_blank" class="social-icon mr-1">
  <img src="../../../assets/images/icons/header icons/Group 22.svg" class="w-100">
</a>
<a href="https://www.tripadvisor.co.uk/Restaurant_Review-g580420-d10331967-Reviews-Indian_Aroma_Restaurant-Ilford_Greater_London_England.html" target="_blank" class="social-icon mr-1"> 
  <img src="../../../assets/images/icons/header icons/Group 21.svg" class="w-100">
</a>
<a href="https://www.youtube.com/channel/UCc4bUg_a2W-7W_gkYiqEgRg" target="_blank" class="social-icon mr-1"> 
  <img src="../../../assets/images/icons/header icons/Group 25.svg" class="w-100">
</a> 
<a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x47d8a71c3d1efde7:0x87110a0c2ece69c9?source=g.page.m.we._" target="_blank" class="social-icon"> 
  <img src="../../../assets/images/icons/header icons/Group 26.svg" class="w-100">
</a>
 