<!-- <app-header-image [backgroundImage]="'assets/images/others/menu.jpg'" 
                  [bgImageAnimate]="true" 
                  [contentOffsetToTop]="false" 
                  [title]="'Menu'"
                  [desc]="'“If more of us valued food and cheer and song above hoarded gold, it would be a merrier world.” – J.R.R. Tolkien'">
</app-header-image>  -->

<app-header-video  *ngIf="settings.header == 'video'"
                [backgroundVideo]="'https://s3.eu-west-2.amazonaws.com/ia.media.prod/images/menuvid.mp4'"
                [contentOffsetToTop]="true"
                [title]="'Menu'"
                [desc]="">
                [isHomePage]="false"
                [fullscreen]="false" style="height:10vh">
</app-header-video>

<div class="theme-container">
    <div class="py-4">
        <div fxLayout="column" fxLayoutAlign="center center" class="text-center">  
            <h1 class="secondary-font">DINE IN MENU</h1>             
            <p>* Menus subject to change.</p>
            <button  class="menu-download mb-2"  mat-button [matTooltip]="'MENU DOWNLOAD' | translate" fxShow="true" fxShow.gt-xs="true">
                <a href="https://s3.eu-west-2.amazonaws.com/ia.media.prod/menu/Indian+Aroma+Restaurant+-+Full+Menu.pdf" target="_blank">MENU DOWNLOAD</a>
              </button> 
        </div>
       <!--  <mat-accordion multi="true">              
            <mat-expansion-panel expanded="false">
                <mat-expansion-panel-header>
                    <mat-panel-title fxLayout="row" fxLayoutAlign="space-between">
                        <div class="fw-500">Allergy Advice</div>
                        <div fxShow="false" fxShow.gt-sm="true" class="text-muted">
                            Question About Allergens
                        </div>
                    </mat-panel-title>               
                </mat-expansion-panel-header>          
                <p>Please               

                    <a target="_blank" routerLink="/contact">contact us</a>
                     at the time of ordering if you have any dietary restrictions or allergies, including those to specific foods.
                    <br/>
                    Alternatively, please speak to our staff about your requirements on 
                    <span><a href="tel:+442085507777">(+44) 208 550 7777</a> </span> or 
                    <span><a href="tel:+447916507777">(+44) 791 650 7777.</a> </span>
                     </p>

              
            </mat-expansion-panel>
        </mat-accordion> -->
    </div>

</div>

<div class="px-3">  
    
    <div class="theme-container mt-4 text-center" *ngIf="categories"> 
        <ul class="nav nav-tabs" role="tablist" style="display: flex;flex-wrap: wrap;">
            
            <!-- <li class="nav-item mr-5 mb-4" *ngFor="let category of categories.categories">
              <a class="nav-link" [className]="category.categoryId == 43256 ? 'active' : 'inactive-class'"  data-toggle="tab" [href]="category.categoryId" (click)="selectCategory(category.categoryId)"> {{category.name}}</a>
            </li> -->
            <li class="nav-item mr-5 mb-4" *ngFor="let category of categories">
              <a class="nav-link" [className]="category.id == 14 ? 'active' : 'inactive-class'"  data-toggle="tab" [href]="category.id" (click)="getMenuItemById(category.id)"> {{category.name}}</a>
            </li>
          </ul>

          <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-5" >
            <div class="divider w-100 yellow"></div>
            <h3 class="text-error ws-nowrap fw-500 p-2 text-#b71c1c">{{submenuName}} <mat-icon class="font-12"><a href="{{menuHref}}" target="_blank">download</a></mat-icon></h3>                       
            <div class="divider w-100 yellow"></div>
        </div>
          <div class="tab-content" *ngFor="let menuItem of submenuItem">
             
            <br>
                <div>
                    <div class="row text-center">
                        <div class="col-lg-10">
                            <h1 class="text-#b71c1c">{{menuItem.name}}</h1>
                            <h3 class="text-bold description">{{menuItem.description}}</h3>
                        </div>
                        <div class="col-lg-2 mt-2">
                            <h3 *ngIf="!menuItem.discount" class="description"> 
                                {{menuItem.price | currency : 'GBP' : 'symbol' : '1.2-2'}}  
                            </h3 > 
                            <h3 class="description">{{menuItem.twoprice}}</h3>
                        </div>
                    </div>
                </div>
                    
            
            <!-- <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-5" *ngIf="initialitem ">
                <div class="divider w-100 yellow"></div>
                <h3 class="text-error ws-nowrap fw-500 p-2 text-#b71c1c" >REGIONAL NON-VEG DISHES</h3>                       
                <div class="divider w-100 yellow"></div>
            </div>  
            <div id="home" class="container tab-pane active" *ngFor="let menuItem of menuItems" ><br>
                <div class="row">
                    <div class="col-lg-10">
                        <h1 class="text-#b71c1c">{{menuItem.name}}</h1>
                        <h3 class="text-bold description">{{menuItem.description}}</h3>
                    </div>
                    <div class="col-lg-2 mt-2">
                        <h3 *ngIf="!menuItem.discount" class="description"> 
                            {{menuItem.price | currency : 'EUR' : 'symbol' : '1.2-2'}}  
                        </h3> 
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>


<!-- <app-our-awards></app-our-awards>  -->
<app-get-in-touch></app-get-in-touch>