<form [formGroup]="feedbackForm"     #fbform="ngForm"  (ngSubmit)="onFeedbackFormSubmit(feedbackForm.value)" class="custom-form pt-2">
    <div class="row">
        <div class="col-lg-6">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Your name</mat-label> 
                <input matInput autocomplete="off" formControlName="name">
                <mat-error *ngIf="feedbackForm.controls.name.errors?.required">Name is required</mat-error>
            </mat-form-field>
        </div>
        <div class="col-lg-6">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>E-mail</mat-label> 
                <input matInput autocomplete="off" formControlName="email">
                <mat-error *ngIf="feedbackForm.controls.email.errors?.required">Email is required</mat-error>
                <mat-error *ngIf="feedbackForm.controls.email.hasError('invalidEmail')">Invalid email address</mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Contact No</mat-label> 
                <input matInput autocomplete="off" formControlName="contact_no">
                <mat-error *ngIf="feedbackForm.controls.contact_no.errors?.required">Contact is required</mat-error>
                <mat-error
                    *ngIf="feedbackForm.controls.contact_no.hasError('invalidPhone')"
                    >Invalid phone Number</mat-error
                  >
            </mat-form-field>
        </div>
    </div>
    <div class="cont">
        <div class="stars">
            <ngx-star-rating class="star rating" [(ngModel)]="rating3" formControlName="rating" (ngModelChange)="change($event)" [id]="'rating3'" ></ngx-star-rating >
            </div>
            <p class="error" *ngIf="ratinginvalid">Rating is required</p>
      </div>
    
    <mat-form-field appearance="outline" class="w-100">
        <mat-label>Your Feedback</mat-label> 
        <textarea matInput formControlName="feedback" rows="6"></textarea> 
        <mat-error *ngIf="feedbackForm.controls.feedback.errors?.required">Feedback is required</mat-error>
    </mat-form-field>
    <div class="w-100 text-center">
        <button mat-flat-button color="primary" class="uppercase" type="submit">Send Message</button> 
    </div> 
</form> 