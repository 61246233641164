<app-header-image [backgroundImage]="'assets/images/others/about.jpg'" 
                  [bgImageAnimate]="true" 
                  [contentOffsetToTop]="true"  
                  [title]="'About Us'"
                  [desc]="'Further information About our team and our Restaurant'">
</app-header-image>  

<div class="px-3">
    <div class="theme-container">
        <mat-card class="main-content-header"> 
            <div class="section pt-1 px-3"> 
                <h1 class="section-title">Our Delicious Story</h1> 


                <p>The Indian Aroma Restaurant started with us serving an affordable, authentic, exemplary Indian food. For all occasions, we deliver truly incredible Indian cuisine. Our clients have given us glowing praise for both our services and our diverse menu options.</p>
<p>We take great pride in sustaining our excellent rating for food hygiene.</p>
<p>We are one of the few Indian restaurants in the UK that uses sustainable packaging and has totally stopped on using plastic. We regularly review and enhance our recipes to produce the greatest Indian food imaginable.</p>

                     
                  
                <div fxLayout="row wrap"> 
                    <div fxFlex="100" fxFlex.gt-sm="50" class="py-3"> 
                        <img src="assets/images/others/about-1.jpg" alt="about-1" class="mw-100">
                    </div> 
                    <div fxFlex="100" fxFlex.gt-sm="50" class="py-3" [ngClass.gt-sm]="(settings.rtl) ? 'pr-5' : 'pl-5'"> 
                        <h1 class="secondary-font primary-color text-center">Our Restaurant</h1>
                        <p>We whole-heartedly invite you to visit our restaurant, which exudes subtle elegance, sophistication and Indian heritage. Here, you may savour a meal that is a unique combination of India’s traditional finest dishes.</p>

<p>We provide an exceptional service and authentic Indian dishes that will make your visit very memorable.</p>
 
<p>Additionally, the restaurant can be reserved for special occasions like birthday parties or private gatherings. Whatever the occasion, we're here to make your special day one you'll never forget.</p>

                    </div> 
                </div> 

                <div fxLayout="row wrap" class="mt-3">  
                    <div fxFlex="100" fxFlex.gt-sm="50" class="py-3" [ngClass.gt-sm]="(settings.rtl) ? 'pl-5' : 'pr-5'"> 
                        <h1 class="secondary-font primary-color text-center">Our Food</h1>

                        <p>The Indian Aroma restaurant is renowned for its staff's genuine passion when preparing and serving each meal for you. We are renowned for its superb service as well as its high-quality, distinctive Indian cuisine accompanied by its traditional recipes.</p>
                        <p>Movie executives, film and television crews, religious and cultural events, charity and business events, as well as religious and cultural events, are a few of our clients.</p>
                        <p>Some of our clientele include movie executives, film and television crews, religious and cultural events, charity and business events, and religious and cultural events. Our restaurant is highly known for both its first-rate customer service and its high-quality, distinctive Indian cuisine served with its authentic recipes.</p>
                        <p>You can choose to sample the best vegetarian and non-vegetarian Indian dishes with us. We serve the meal using our exquisite and traditional Indian recipes. Our Indian Street food pallet has a variety of flavours and textures that will get you lost in Indian food marine.</p>
                        


                       
                    </div> 
                    <div fxFlex="100" fxFlex.gt-sm="50" class="py-3"> 
                        <img src="assets/images/others/about-2.jpg" alt="about-2" class="mw-100">
                    </div> 
                </div> 
                <div fxLayout="row wrap"> 
                    <div fxFlex="100" fxFlex.gt-sm="50" class="py-3"> 
                        <img src="assets/images/others/about-3.jpg" alt="about-1" class="mw-100">
                    </div> 
                    <div fxFlex="100" fxFlex.gt-sm="50" class="py-3" [ngClass.gt-sm]="(settings.rtl) ? 'pr-5' : 'pl-5'"> 
                        <h1 class="secondary-font primary-color text-center">Our Service</h1>

                        <p>We provide services of the highest calibre that are unmatched and, most importantly, unforgettable. Our staff members can provide you advice on any of our meals because they are fluent in various Indian languages in addition to English.</p>
 
                        <p>We like what we do, and we are sure that you will join the long list of satisfied Indian Aroma Restaurant patrons.</p>
                         
                        <p>Customers of Indian Aroma may relax knowing that our advice and recommendations are backed by the expertise of a staff with a wealth of collective experience in producing fantastic Indian meals.</p>
                         
                        
                      



                    </div> 
                </div> 

    
                <div fxLayout="row wrap" class="flex-wrapper mt-3"> 
                    <div fxFlex="100" class="px-3">
                        <h2 class="secondary-font primary-color">Our Working Hours</h2> 
                        <p>Opening Times may vary during seasonal and/or bank holidays.</p>
                    </div> 
                    <div *ngFor="let item of workingHours" fxFlex="100" fxFlex.gt-md="25" fxFlex.gt-sm="50" class="p-3"> 
                        <mat-chip-listbox> 
                            <mat-chip color="primary" highlighted selectable="false">{{item.day}}</mat-chip>
                            <mat-chip-option selectable="false">
                                <div fxLayout="row" fxLayoutAlign="start center">
                                    <mat-icon class="mr-1 text-muted">schedule</mat-icon>{{item.hours}}
                                </div> 
                            </mat-chip-option>
                        </mat-chip-listbox> 
                    </div> 
                </div>

                
                <div fxLayout="row wrap" class="mt-3">  
                    <div fxFlex="100" fxFlex.gt-sm="50" class="py-3" [ngClass.gt-sm]="(settings.rtl) ? 'pl-5' : 'pr-5'"> 
                        <h1 class="secondary-font primary-color text-center">Our Location</h1>
                       <p>33 Woodford Avenue Gants Hill, Ilford IG2 6UF England
                    </p>
                    </div> 
                    <div fxFlex="100" fxFlex.gt-sm="50" class="py-3"> 
                        <img src="assets/images/others/about-4.jpg" alt="about-2" class="mw-100">
                    </div> 
                </div> 
              <!--   <div fxLayout="row wrap" class="flex-wrapper mt-3"> 
                    <div fxFlex="100" fxFlex.gt-sm="50" class="p-3">                             
                        <p class="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse semper lobortis quam, id imperdiet mi feugiat quis. Cras a odio posuere, rhoncus quam vitae, commodo neque. Nunc mollis velit vulputate, volutpat diam vitae, egestas turpis. In posuere tempor lorem, sit amet pulvinar nunc accumsan a. Quisque sem tellus, congue at eleifend sit amet, consectetur sit amet est. Integer sodales quam quis elit commodo consequat. Aenean posuere augue a justo gravida elementum laoreet tincidunt enim. Nullam sagittis mauris id dui scelerisque, eget dignissim est hendrerit. Praesent fringilla commodo egestas. Suspendisse bibendum purus vitae mi mattis laoreet.</p>
                    </div> 
                    <div fxFlex="100" fxFlex.gt-sm="50" class="p-3">  
                        <p class="mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse semper lobortis quam, id imperdiet mi feugiat quis. Cras a odio posuere, rhoncus quam vitae, commodo neque. Nunc mollis velit vulputate, volutpat diam vitae, egestas turpis. In posuere tempor lorem, sit amet pulvinar nunc accumsan a. Quisque sem tellus, congue at eleifend sit amet, consectetur sit amet est. Integer sodales quam quis elit commodo consequat. Aenean posuere augue a justo gravida elementum laoreet tincidunt enim. Nullam sagittis mauris id dui scelerisque, eget dignissim est hendrerit. Praesent fringilla commodo egestas. Suspendisse bibendum purus vitae mi mattis laoreet.</p>
                    </div> 
                </div>  -->
            </div>  
        </mat-card>  
    </div> 
</div>    

<app-our-services></app-our-services> 
<app-our-awards></app-our-awards>  
<!-- <app-get-app></app-get-app> -->
