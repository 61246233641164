import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';  
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { SwiperModule } from '../theme/components/swiper/swiper.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';


import { PipesModule } from '../theme/pipes/pipes.module';
import { DirectivesModule } from '../theme/directives/directives.module';

import { LogoComponent } from './logo/logo.component';
import { HeaderImageComponent } from './header-image/header-image.component';
import { HeaderCarouselComponent } from './header-carousel/header-carousel.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { OurAwardsComponent } from './our-awards/our-awards.component';
import { GetInTouchComponent } from './get-in-touch/get-in-touch.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MenuItemsCarouselComponent } from './menu-items-carousel/menu-items-carousel.component';
import { CartOverviewComponent } from './cart-overview/cart-overview.component';
import { QuantityCounterComponent } from './quantity-counter/quantity-counter.component';
import { RatingComponent } from './rating/rating.component';
import { CommentsComponent } from './comments/comments.component';
import { MenuItemsToolbarComponent } from './menu-items-toolbar/menu-items-toolbar.component';
import { MenuItemHoverableComponent } from './menu-item-hoverable/menu-item-hoverable.component';
import { OurChefsComponent } from './our-chefs/our-chefs.component';
import { TimelineComponent } from './timeline/timeline.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { GetAppComponent } from './get-app/get-app.component';
import { ReservationFormComponent } from './reservation-form/reservation-form.component';
import { ReservationDialogComponent } from './reservation-dialog/reservation-dialog.component'; 
import { DialogHeaderControlsComponent } from './dialog-header-controls/dialog-header-controls.component'; 
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component'; 
import { SocialIconsComponent } from './social-icons/social-icons.component';
import { TodayMenuComponent } from './today-menu/today-menu.component';
import { LangComponent } from './lang/lang.component';
import { OrderDetailsDialogComponent } from './order-details-dialog/order-details-dialog.component';
import { HeaderVideoComponent } from './header-video/header-video.component';
import { GalleryMenuComponent } from './gallery-menu/gallery-menu.component';
import { ReviewComponent } from './review/review.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { RefundPolicyComponent } from './refund-policy/refund-policy.component';
import { AllergyAdviceComponent } from './allergy-advice/allergy-advice.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { OrderOnlineComponent } from './order-online/order-online.component';
import { OrderOnlineDialogComponent } from './order-online-dialog/order-online-dialog.component';
import { NewsletterThankyouComponent } from './newsletter-thankyou/newsletter-thankyou.component';
import { NewsletterErrorComponent } from './newsletter-error/newsletter-error.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { ErrorpageComponent } from './error-page/error-page.component';
import { ThankyouFeedbackComponent } from './thank-you-feedback/thank-you-feedback.component';
import { DeliveryPartnersComponent } from './delivery-partners/delivery-partners.component';
import { HomeBannerComponent } from './home-banner/home-banner.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { OnloadPopupComponent } from './onloadPopup/onloadPopup.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AboutComponent } from '../pages/about/about.component';
import { MenuComponent } from '../pages/menu/menu.component';
import { ContactComponent } from '../pages/contact/contact.component';
import { HomeComponent } from '../pages/home/home.component';
import { LockScreenComponent } from '../pages/lock-screen/lock-screen.component';
import { BlogComponent } from '../pages/blog/blog.component';
import { OrderonlinepageComponent } from '../pages/order-online-page/order-online-page.component';
import { PressComponent } from '../pages/press/press.component';
import { ReservationComponent } from '../pages/reservation/reservation.component';
import { TermsConditionsComponent } from '../pages/terms-conditions/terms-conditions.component';
import { GalleryCustomeComponent } from '../pages/gallery-custome/gallery-custome.component';
import { NotFoundComponent } from '../pages/not-found/not-found.component';
import { MarketStallComponent } from '../pages/market-stall/market-stall.component';
import { VerticalMenuComponent } from '../theme/components/menu/vertical-menu/vertical-menu.component';
import { FooterComponent } from '../theme/components/footer/footer.component';
import { HorizontalMenuComponent } from '../theme/components/menu/horizontal-menu/horizontal-menu.component';
import { Toolbar1Component } from '../theme/components/toolbar1/toolbar1.component';
import { ContactsComponent } from '../theme/components/contacts/contacts.component';
import { UserMenuComponent } from '../theme/components/user-menu/user-menu.component';
import { PagesComponent } from '../pages/pages.component';
import { AppComponent } from '../app.component';
@NgModule({
  declarations: [
    LogoComponent,
    HeaderImageComponent,
    HeaderCarouselComponent,
    OurServicesComponent,
    OurAwardsComponent,
    GetInTouchComponent,
    MenuItemComponent,
    MenuItemsCarouselComponent,
    CartOverviewComponent,
    QuantityCounterComponent,
    RatingComponent,
    CommentsComponent,
    MenuItemsToolbarComponent,
    MenuItemHoverableComponent,
    OurChefsComponent,
    TimelineComponent,
    TestimonialsComponent,
    GetAppComponent,
    ReservationFormComponent,
    ReservationDialogComponent,
    DialogHeaderControlsComponent,
    ImageUploadComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    SocialIconsComponent,
    TodayMenuComponent,
    LangComponent,
    OrderDetailsDialogComponent,
    HeaderVideoComponent,
    GalleryMenuComponent,
    ReviewComponent,
    ThankyouComponent,
    RefundPolicyComponent,
    AllergyAdviceComponent,
    CookiePolicyComponent,
    PrivacyPolicyComponent,
    TermsAndConditionComponent,
    OrderOnlineComponent,
    OnloadPopupComponent,
    OrderOnlineDialogComponent,
    NewsletterThankyouComponent,
    NewsletterErrorComponent,
    ErrorpageComponent,
    ThankyouFeedbackComponent,
    DeliveryPartnersComponent,
    HomeBannerComponent,SitemapComponent,
    PagesComponent,
    UserMenuComponent,
    ContactsComponent,
    Toolbar1Component,
    HorizontalMenuComponent,
    FooterComponent,
    VerticalMenuComponent,
    MarketStallComponent,
    NotFoundComponent,
    GalleryCustomeComponent,
    TermsConditionsComponent,
    ReservationComponent,
    PressComponent,
    OrderonlinepageComponent,
    BlogComponent,
    LockScreenComponent,
    HomeComponent,
    ContactComponent,
    HeaderVideoComponent,
    MenuComponent,
    UnsubscribeComponent,
    ThankyouComponent,
    OurAwardsComponent,
    AboutComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule, 
    FlexLayoutModule,
    TranslateModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PipesModule,
    DirectivesModule,
    NgxStarRatingModule ,
    MatIconModule,
    SwiperModule,
    MatChipsModule
  ],
  exports: [
    RouterModule,
    ReactiveFormsModule,
    FormsModule, 
    FlexLayoutModule,
    TranslateModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    PipesModule,
    DirectivesModule,
    LogoComponent,
    HeaderImageComponent,
    HeaderCarouselComponent,
    OurServicesComponent,
    OurAwardsComponent,
    GetInTouchComponent,
    MenuItemComponent,
    MenuItemsCarouselComponent,
    QuantityCounterComponent,
    RatingComponent,
    CommentsComponent,
    MenuItemsToolbarComponent,
    MenuItemHoverableComponent,
    OurChefsComponent,
    TimelineComponent,
    TestimonialsComponent,
    GetAppComponent,
    ReservationFormComponent,
    ReservationDialogComponent,
    DialogHeaderControlsComponent,
    ImageUploadComponent,
    ConfirmDialogComponent,
    AlertDialogComponent,
    SocialIconsComponent,
    TodayMenuComponent,
    LangComponent,
    OrderDetailsDialogComponent,
    HeaderVideoComponent,
    GalleryMenuComponent,
    ReviewComponent,
    ThankyouComponent,
    RefundPolicyComponent,
    AllergyAdviceComponent,
    CookiePolicyComponent,
    PrivacyPolicyComponent,
    TermsAndConditionComponent,
    TermsAndConditionComponent,
    OrderOnlineComponent,
    OrderOnlineDialogComponent,OnloadPopupComponent,
    DeliveryPartnersComponent,HomeBannerComponent,SitemapComponent,
    MatChipsModule
  ],
  providers:[
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}}
  ]
})
export class SharedModule { }
