<div class="p-3">
    <div class="theme-container">
  
        <div class="my-4">
            <div fxLayout="column" fxLayoutAlign="center center" class="text-center">  
                <h1 class="secondary-font">Refund Policy</h1>             
                <p>When you pick up your order to go, please double check that everything is correct. </p>
            </div>
  
            <div fxLayout="row" fxLayoutAlign="center center" class="my-3">
                <mat-card class="p-5">
                    <p>
                        Notify a management if there is a problem with the quality of the food or the service so that we may make the necessary adjustments and ensure that you depart feeling satisfied. 
                    </p>
                    <p>
                        Please get in touch with our corporate office at <a href="mailto:info@indianaroma.co.uk">info&#64;indianaroma.co.uk</a> or <a href="tel:0208 550 7777"> (+44) 0208 550 7777</a> if you are still not happy with the service you have received.
                    </p>
                </mat-card>
            </div>
            
        </div>
  
    </div>
  </div>