<span fxLayoutAlign="center center" class="item">
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">call</mat-icon>
  <span class="px-1"><a href="tel:+442085507777">(+44) 0 208 550 7777</a></span>
</span>
<span *ngIf="dividers" class="v-divider" fxShow="false" fxShow.gt-xs></span>
<span fxLayoutAlign="center center" class="item" fxShow="false" fxShow.gt-xs>
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">location_on</mat-icon>
  <span class="px-1"><a href="https://goo.gl/maps/JuVK89DfJqGLLjh18" target="_blank">33 Woodford Avenue Gants Hill, Ilford IG2 6UF England</a>
  </span>
</span>
<span *ngIf="dividers" class="v-divider" fxShow="false" fxShow.gt-sm></span>
<span fxLayoutAlign="center center" class="item" fxShow="false" fxShow.gt-sm>
  <mat-icon [color]="iconColor" [ngClass]="'mat-icon-' + iconSize">mail</mat-icon>
  <span class="px-1"><a href="mailto:info@indianaroma.co.uk" target="_blank">info&#64;indianaroma.co.uk</a></span>
</span>
