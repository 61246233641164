<div class="header bg-primary">
    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
        <h2 class="fw-500 secondary-font">Let's Order</h2>
        <app-dialog-header-controls [dialogRef]="dialogRef"></app-dialog-header-controls> 
    </div>   
</div> 
<mat-dialog-content>  
    <div class="pt-3">
        <h1 class="wel fw-500 text-center mb-3">Welcome to Indian Aroma</h1>
        <app-order-online-dialog (onFormSubmit)="onMakeReservation($event)"></app-order-online-dialog> 
    </div>  
</mat-dialog-content> 