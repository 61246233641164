<div class="text-center relatvie">
    <!-- <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="home"></mat-icon> -->
    <mat-icon (click)="closeDialog()" class="close-icon">close</mat-icon>

    <h1 *ngIf="bannerState.restaurantBannerTitle">{{bannerState.restaurantBannerTitle}}</h1>

    <!-- if closed below img -->
    <img *ngIf="bannerState.restaurantStatus=='CLOSED'" src="assets/images/dialog/closed.png" class="home-popup" alt="">
    <!-- if open and offer below image -->
    <img *ngIf="!(bannerState.restaurantStatus=='CLOSED')" [src]="bannerState.restaurantBannerUrlPath" class="home-popup" alt="">
    <h3 *ngIf="bannerState.restaurantBannerDescription1">{{bannerState.restaurantBannerDescription1}}</h3>
    <p *ngIf="bannerState.restaurantBannerDescription2">{{bannerState.restaurantBannerDescription2}}</p>
</div>
<!-- <br> -->
<!-- {{bannerState | json}} -->