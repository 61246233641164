import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
export enum ToggleEnum {
  Option1,
  Option2
}

@Component({
  selector: 'app-order-online-dialog',
  templateUrl: './order-online-dialog.component.html',
  styleUrls: ['./order-online-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderOnlineDialogComponent implements OnInit {
  toggleEnum = ToggleEnum;
  selectedState = ToggleEnum.Option1;
  enterPostcode :any;
  erorrMessage: boolean = false;
  inValidCode:boolean = false;
  isNotMessage: boolean = false;

  regex='/^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/'

  public form!: UntypedFormGroup;
  public hours = ['1:00am','2:00am','3:00am','4:00am','5:00am','6:00am','7:00am','8:00am','9:00am','10:00am','11:00am','12:00am',
                  '1:00pm','2:00pm','3:00pm','4:00pm','5:00pm','6:00pm','7:00pm','8:00pm','9:00pm','10:00pm','11:00pm','12:00pm']; 
  public today = new Date();
  public selectedCategoryId:number = 0;
  insideUrl = 'https://indianaroma.gonnaorder.com';
  outSideUrl='https://indianaroma-outside-zones.gonnaorder.com';
  OutsideSlotsA = ["RM11", "RM12", "RM3", "RM7", "RM1","IG11","SE1","IG10","SE3","SE2","SE5","SE4","SE7","SE6","SE9","SE8","DA13","DA12","DA11","DA10","SE15","SE16","SE13","SE14","DA2","SE11","DA1","SE12","DA4","DA3","SE10","DA6","DA5","DA8","RM6","DA7","RM5","RM8","DA9","SE17","SE18","BR1","BR3","BR2","BR5","BR4","BR7","BR6","BR8","DA18","DA17","DA16","DA15","DA14"];
  OutsideSlotsA1 =["rm11", "rm12", "rm3", "rm7", "rm1","ig11","se1","ig10","se3","se2","se5","se4","se7","se6","se9","se8","da13","da12","da11","da10","se15","se16","se13","se14","da2","se11","da1","se12","da4","da3","se10","da6","da5","da8","rm6","da7","rm5","rm8","da9","se17","se18","br1","br3","br2","br5","br4","br7","br6","br8","da18","da17","da16","da15","da14"]
  @Output() onFormSubmit: EventEmitter<any> = new EventEmitter(); 
  PrimaryA = ["IG1","IG2","IG5","IG4","IG6","IG3","IG7","IG8","E18"];
  PrimaryA1 = ["ig1","ig2","ig5","ig4","ig6","ig3","ig7","ig8","e18"];
  constructor(public formBuilder: UntypedFormBuilder,
    private route:Router) { }

  ngOnInit(): void { 
    this.form = this.formBuilder.group({
      postCode: ['', [Validators.required,Validators.pattern('^[0-5]?[0-9]$'),Validators.maxLength(4)]],
    });
  }

  public onChangeCategory(event:any){ 
    this.selectCategory(event.value);
  }

  public selectCategory(id:number){
  }


  changeCode(data:any){  
  }

  public submit(){
    try{
      const parts = this.enterPostcode.match(/^([A-Za-z]{1,2}\d{1,2}[A-Za-z]?)\s*(\d[A-Za-z]{2})$/);
      if(this.OutsideSlotsA.includes(parts[1].toUpperCase()) || this.OutsideSlotsA1.includes(parts[1])){
        this.erorrMessage = false;
        this.inValidCode = false;
        this.isNotMessage = false;
        this.goToLink(this.outSideUrl);
       } else if(this.PrimaryA.includes(parts[1].toUpperCase()) || this.PrimaryA1.includes(parts[1])){
        this.goToLink(this.insideUrl);
        this.erorrMessage = false;
        this.inValidCode = false;
        this.isNotMessage = false;
       } else {
        this.erorrMessage = false;
        this.isNotMessage = true;
        this.inValidCode = false;
       }
    } catch{
      this.erorrMessage = false;
      this.isNotMessage = false;
      this.inValidCode = true;
    }

    if(this.enterPostcode == '' || this.enterPostcode == null || this.enterPostcode == undefined) {
      this.erorrMessage = true;
      this.inValidCode = false;
    }
  }

  tabClick(tab:any) {
  }

  onChange($event:any) {
    this.selectedState = $event.value;
  }

  online(){
    window.open('https://indianaroma.gonnaorder.com/', "_blank");
  }


  goToLink(url:any) {
    window.open(url, "_blank");
  }

}
