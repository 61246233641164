<footer class="footerbac">    
    <div class="px-3">
        <div class="theme-container"> 
            <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="center center" class="content border-lighter py-5">
                <div fxFlex="100" fxFlex.gt-sm="40" fxLayout="column" fxLayoutAlign="center center"> 
                    <h1 class="secondary-font">Stay updated *</h1>
                    <p class="desc">By subscribing to our newsletter you agree to receive personalized communications and marketing offers by email from us.</p>
                </div>
                <form [formGroup]="subscribeForm" (ngSubmit)="onSubscribeFormSubmit(subscribeForm.value)" class="custom-form"
                
                #form="ngForm"  fxLayout="row" fxFlex="100" fxFlex.gt-sm="42.4" ngClass.sm="mt-2" ngClass.xs="mt-2">
                    <mat-form-field appearance="outline" floatLabel="always" class="subscribe-input" fxFlex>
                        <mat-label>E-mail</mat-label> 
                        <!-- <input matInput autocomplete="off" formControlName="email" placeholder="Your email address..."> -->
                        <input matInput autocomplete="off" formControlName="email">
                        <mat-error *ngIf="subscribeForm.controls.email.hasError('invalidEmail')" class="mt-4">Invalid email address</mat-error>                  
                        <mat-error  *ngIf="subscribeForm.controls.email.errors?.required" class="mt-4"> Email is required</mat-error>                  
                    </mat-form-field>
                    <button mat-flat-button type="submit" class="subscribe-btn" color="primary">Subscribe</button>
                </form>
            </div> 
            <div class="py-5 content border-lighter"> 
                <div fxLayout="row wrap" fxLayoutAlign="space-between"> 
                    <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="45"> 
                        <a class="logo" routerLink="/"><app-logo></app-logo></a>
                        <!-- <p class="mt-4 mb-3 desc">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nostrum perspiciatis cupiditate numquam odio explicabo accusantium deserunt incidunt.</p> -->
                        <p fxLayout="row" fxLayoutAlign="start center" class="mt-2">
                            <mat-icon color="primary">location_on</mat-icon>
                            <span class="mx-2"><a href="https://goo.gl/maps/JuVK89DfJqGLLjh18" target="_blank">33 Woodford Avenue Gants Hill, Ilford IG2 6UF England</a></span>
                        </p>
                        <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                            <mat-icon color="primary">call</mat-icon>
                            <span class="mx-2"><a href="tel:+442085507777">(+44) 0 208 550 7777</a></span>
                        </p>
                        <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                            <mat-icon color="primary">mail_outline</mat-icon>
                            <span class="mx-2"><a href="mailto:info@indianaroma.co.uk" target="_blank">info&#64;indianaroma.co.uk</a></span>
                        </p>
                        <p fxLayout="row" fxLayoutAlign="start center" class="mt-1">
                            <mat-icon color="primary">schedule</mat-icon>
                            <span class="mx-2">Mon-Sun: 5 PM - 10:30 PM</span>
                        </p>
                        <div  fxLayout="row" fxLayoutAlign="start center" >
                            <a href="https://wa.me/+447916507777" target="_blank" class="social-icon "> 
                                <img src="../../../../assets/images/icons/footer icons/whtsapp.svg" class="w-100">
                              </a>
                              <a href="https://www.facebook.com/indianaromalondon"  target="_blank" class="social-icon ">
                                <img src="../../../../assets/images/icons/footer icons/facebook.svg" class="w-100">
                              </a>
                              <a href="https://www.instagram.com/indian_aroma_london/" target="_blank" class="social-icon ">
                                <img src="../../../../assets/images/icons/footer icons/insta.svg" class="w-100">
                              </a>
                              <a href="https://www.tripadvisor.co.uk/Restaurant_Review-g580420-d10331967-Reviews-Indian_Aroma_Restaurant-Ilford_Greater_London_England.html" target="_blank" class="social-icon "> 
                                <img src="../../../../assets/images/icons/footer icons/tripad.svg" class="w-100">
                              </a>
                              <a href="https://www.youtube.com/channel/UCc4bUg_a2W-7W_gkYiqEgRg" target="_blank" class="social-icon "> 
                                <img src="../../../../assets/images/icons/footer icons/youtube.svg" class="w-100">
                              </a> 
                              <a href="https://twitter.com/IndianAroma_LDN" target="_blank" class="social-icon "> 
                                <img src="../../../../assets/images/icons/footer icons/twitter.svg" class="w-100">
                              </a> 
                              <a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x47d8a71c3d1efde7:0x87110a0c2ece69c9?source=g.page.m.we._" target="_blank" class="social-icon"> 
                                <img src="../../../../assets/images/icons/footer icons/googleplus.svg" class="w-100">
                              </a>
                              <a href="https://www.pinterest.co.uk/indianaromalondon/" target="_blank" class="social-icon"> 
                                <img src="../../../../assets/images/icons/footer icons/printest.svg" style="width: 25px;
                                margin-top: -12px;">
                              </a> 
                              <a href="https://www.linkedin.com/company/indian-aroma-cuisine-limited/" target="_blank" class="social-icon"> 
                                <img src="../../../assets/images/icons/contact icons/linkdin.png" style="width: 25px;
                                margin-top: -12px; margin-left: 12px;">
                              </a>
                        </div>               
                        
                       
                    </div>

                        
                   
                        <div fxFlex="100" fxFlex.gt-sm="30" fxFlex.sm="45" ngClass.xs="pt-5"> 
                        <h1 class="secondary-font">Have Feedback?</h1>
                        <p class="desc">We'd love to hear from you!</p>
                        <app-review></app-review>
                        <!-- <form [formGroup]="feedbackForm" (ngSubmit)="onFeedbackFormSubmit(feedbackForm.value)" class="custom-form pt-2">                            
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>E-mail</mat-label> 
                                <input matInput autocomplete="off" formControlName="email">
                                <mat-error *ngIf="feedbackForm.controls.email.errors?.required">Email is required</mat-error>
                                <mat-error *ngIf="feedbackForm.controls.email.hasError('invalidEmail')">Invalid email address</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Message for us</mat-label> 
                                <textarea matInput formControlName="message" rows="6"></textarea> 
                                <mat-error *ngIf="feedbackForm.controls.message.errors?.required">Message is required</mat-error>
                            </mat-form-field>
                            <div class="w-100 text-center">
                                <button mat-flat-button color="primary" class="uppercase" type="submit">Submit</button> 
                            </div> 
                        </form>  -->
                    </div>
 
                    <div fxFlex="100" fxFlex.gt-sm="30" ngClass.lt-md="pt-5"> 
                        <h1 class="secondary-font mb-3">Our location</h1>   
                       
                        <div class="contact-map">
                            <iframe
                              class="map"
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2479.494421795278!2d0.0625836157725634!3d51.57750127964681!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a6ea02a68f01%3A0x3f9f7a97f7ff7b5a!2s33%20Woodford%20Ave%2C%20Gants%20Hill%2C%20Ilford%20IG2%206UF!5e0!3m2!1sen!2suk!4v1645459915806!5m2!1sen!2suk"
                            ></iframe>
                          </div>
                          <h1 class="secondary-font mb-3">Restaurant Panorama</h1> 
                        <div class="">
                            <iframe src="https://www.google.com/maps/embed?pb=!4v1660744499096!6m8!1m7!1sCAoSLEFGMVFpcE9yZUZldjVlYWVIUkk5X2k4Q1VVOE1aTndrTDQwWVd4WXpsVHJj!2m2!1d51.577544353375!2d0.064838072181193!3f228.74!4f-11.39!5f0.7820865974627469" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <p class="desc" routerLink="/sitemap">Sitemap</p>
                        <img src="../../../../assets/images/white logo.svg" alt="Indian Aroma" class="footerlogo">
                        <img src="../../../../assets/images/eco friendly logo.svg" alt="Eco-Friendly Image" class="footerlogo">
                        <img src="../../../../assets/images/Halal food 1.svg" alt="halal Image" class="footerlogo">
                        
                    </div>
                </div>  
            </div>
            <div class="footer-bottom-area">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
    
                        <p style="float: left;font-size: xx-small;">
                            Copyright &#64;2022 All rights reserved by <a target="_blank" href="https://indianaroma.co.uk/">Indian Aroma Cuisine Limited</a>
    
                            | Site Developed by <a target="_blank" href="https://www.radsuntech.com/">RAD SUN TECHNOLOGY LIMITED</a></p>
                    </div>
                    <div class="col-lg-6 col-md-6" style="font-size: xx-small;">
                        <ul>
                            <!-- <li><a routerLink="/refund-policy" >Refund Policy</a></li> -->
                            <li><a routerLink="/allergy-advice" >Allergy Advice</a></li>
                            <li><a routerLink="/cookie-policy" >Cookie Policy</a></li>
                            <li><a routerLink="/privacy-policy" >Privacy Policy</a></li>
                            <li><a routerLink="/terms-of-service">Terms & Conditions</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>      
</footer>