<div class="p-3">  
    <div class="theme-container"> 
    
    <mat-card class="main-content-header p-0 mb-4"> 
    
    <div  fxLayout="column" fxLayoutAlign="center center" class="text-center py-5"> 
      <img src="../../../assets/images/tick svg.svg" alt="">
      <h1 class="secondary-font mt-5">Thank you for subscribing to our newsletter!</h1>
      <p> We'll be in touch very soon.</p>  
      <a [routerLink]="['/']" mat-raised-button color="primary" class="mt-3">Go to Homepage</a> 
    </div>  
    
    
    
    </mat-card> 
    
    </div> 
    </div> 