<mat-drawer-container fxFlexFill>
  <div fxFlexFill fxLayout="column" fxLayoutAlign="center center" class="p-3 lock-screen"> 
    <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" fxLayout="row" fxLayoutAlign="center center"> 
        <h3 class="name text-muted">Adam Sandler</h3>  
        <img src="assets/images/profile/adam.jpg" alt="" class="mat-elevation-z4">             
        <input placeholder="Enter password" formControlName="password" required type="password">
        <button mat-icon-button color="primary" type="submit" class="submit" [disabled]="!form.valid">
          <mat-icon>arrow_forward</mat-icon>
        </button>
        <a mat-button routerLink="/login" color="primary">Or sign in as a different user</a>  
    </form>
     <p class="time">{{ date | date:"hh:mm:ss a" }}</p> 
  </div>
</mat-drawer-container>