<div class="section">
    <div class="px-3">
        <div class="theme-container">
            <h1 class="section-title mb-3">Our Delivery Partners</h1>
            
        <div class="deliverPartners">
            <a href="https://deliveroo.co.uk/menu/london/barkingside/indian-aroma-restaurant-woodford-ave?geohash=u10j3bdme3fd
            " target="_blank">
                <img src="assets/images/deliveryPartners/deliveroo.svg" />
            </a>

            <a href="https://www.just-eat.co.uk/restaurants-shri-bheemas-indian-restaurant-new-ilford-ig2/menu?adj_tracker=84l7irb&adj_campaign=CM_S_G_GBR_EN_%5BRES%5D_%5BENGM%5D_OD_London_1006886&gclid=Cj0KCQjwxveXBhDDARIsAI0Q0x02GrHQpBQivLOgR69OHCuIYNmno3bYIqJO52zRUqt18MIGG-67UakaAhI2EALw_wcB&gclsrc=aw.ds" target="_blank">
                <img src="assets/images/deliveryPartners/just eat.svg" />
            </a>

            <a href="https://www.ubereats.com/gb/store/indian-aroma/xcoDm72tTJaTw5LPPrcqsQ" target="-blank">
                <img src="assets/images/deliveryPartners/uber eat.svg" />

            </a>
            
        
            
        </div>    
           
        </div>
    </div> 
    </div>