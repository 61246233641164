<div class="header bg-primary">
  <div fxLayout="row wrap" fxLayoutAlign="space-between center">
      <!-- <h2 class="fw-500 secondary-font">What's New</h2> -->
      <h2 class="fw-500 secondary-font">Award Wining Restaurant</h2>
      <app-dialog-header-controls [dialogRef]="dialogRef"></app-dialog-header-controls> 
  </div>   
</div> 
<mat-dialog-content  class="mtc">  
  <div class="pt-3">
    <div class="row" class="custom-grid">
      <div class="imagedata">
      
        
        <img src="../../../assets/images/awards/pop up new.png" alt="user-image" height="100%" width="100%" style="padding: 5px;">
        
      </div>
    </div>     
      <!-- <app-order-online-dialog (onFormSubmit)="onMakeReservation($event)"></app-order-online-dialog>  -->
  </div> 
  <!-- <div class="bg-red">
    <h2>Opening hours</h2>
    <p>From 14th October 2022</p>
  </div>  
  <div *ngFor="let data of workingHours">
    <div class="d-flex">
      <div>{{data.day}}</div>
      <div>{{data.hours}}</div>
    </div>
    <hr>
  </div> -->
</mat-dialog-content>

