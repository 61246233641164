
    <!-- <div fxLayout="row" fxLayoutAlign="center center" class="h-100 mt-5 mb-5">
        <div fxFlex="80" fxFlex.gt-sm="30" fxFlex.sm="60">
            <mat-card class="p-0 mat-elevation-z6 box">
                <div fxLayout="column" fxLayoutAlign="center center" class="bg-primary box-header">
                    <mat-icon class="mat-icon-xlg">error</mat-icon>
                    <h3>Email already exists in the subscribers list</h3>
                </div>
                <mat-card-content fxLayout="column" fxLayoutAlign="end center" class="box-content">
                    <mat-card fxLayout="column" fxLayoutAlign="center center" class="mat-elevation-z8 box-content-inner">
                        <p>Thank you for subscribing us</p>
                        <p class="box-text"></p> 
                        
                        </mat-card>
                    <div class="box-footer">
                        <button mat-raised-button color="primary" class="mat-elevation-z8 bg-primary" type="button" routerLink="/">
                           Go to home
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>          
        </div>
    </div>
     -->
     

<div class="p-3">  
    <div class="theme-container"> 
    
    <mat-card class="main-content-header p-0 mb-4"> 
    
    <div  fxLayout="column" fxLayoutAlign="center center" class="text-center py-5"> 
      <img src="../../../assets/images/warn.svg" alt="warn image"> 
      <h1 class="secondary-font mt-5">Email already exists in the subscribers list</h1>
      <p>Thank you for subscribing us.</p>  
      <a [routerLink]="['/']" mat-raised-button color="primary" class="mt-3">Go to Homepage</a> 
    </div>  
    
    > 
    
    </mat-card> 
    
    </div> 
    </div> 