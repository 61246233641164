import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {  Router } from "@angular/router";

@Component({
  selector: 'app-onloadPopup',
  templateUrl: './onloadPopup.component.html',
  styleUrls: ['./onloadPopup.component.css']
})
export class OnloadPopupComponent implements OnInit {
  dataTree: boolean | undefined;
  public workingHours = [
    { day: "Monday(New year special)", hours: "05PM - 10:30pm " },
    { day: "Tuesday", hours: "05PM - 10:30PM" },
    { day: "Wednesday", hours: "05PM - 10:30PM" },
    { day: "Thursday", hours: "05PM - 10:30PM" },
    { day: "Friday", hours: "05PM - 10:30PM" },
    { day: "Saturday", hours: "05PM - 10:30PM" },
    { day: "Sunday", hours: "05PM - 10:30pm" }
  ]
  
  constructor(
    public dialogRef: MatDialogRef<OnloadPopupComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) { }
    

    ngOnInit(): void {
      // Subscribe to route events
      const isHomepage = this.router.url === '/';
          
      // Open or close the dialog based on the route
      this.dataTree = isHomepage;
      
      // If you want to close the dialog after a certain time, you can use setTimeout
      if (!isHomepage) {
        
          this.dialogRef.close();
      }
      
        setTimeout(() => {
          this.dialogRef.close();
        }, 3000);
    }

  public onMakeReservation(event:any){
    console.log('event: ', event);
    this.dialogRef.close(event.value);
  }

  public selectCategory(id:number){
  }

}
