import { AppService } from 'src/app/app.service';
import { Component, OnInit } from '@angular/core';
import { AppSettings, Settings } from 'src/app/app.settings';

@Component({
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.css']
})
export class PressComponent implements OnInit {
  public workingHours = [
    { day: "Monday", hours: "05PM TO 11pm " },
    { day: "Tuesday", hours: "05PM TO 11pm" },
    { day: "Wednesday", hours: "05PM TO 11pm" },
    { day: "Thursday", hours: "05PM TO 11pm" },
    { day: "Friday", hours: "05PM TO 11pm" },
    { day: "Saturday", hours: "05PM TO 11pm" },
    { day: "Sunday", hours: "05PM TO 11pm" }
  ]


  schedule:any[]=[]
  // {"MON": "17:00:00 - 22:00:00", "TUE": "CLOSED", "WED": "17:00:00 - 22:00:00", "THU": "17:00:00 - 22:00:00", "FRI": "17:00:00 - 22:00:00", "SAT": "17:00:00 - 22:00:00", "SUN": "17:00:00 - 22:00:00"}
  settings: Settings;
  constructor(public appSettings:AppSettings, private appService: AppService) { 
    this.settings = this.appSettings.settings; 
  }

  ngOnInit(): void { 
    this.getResturantSchedule()
  }

  getResturantSchedule(){
    this.appService.getResturantSchedule().subscribe((data)=>{
      for (const [key, value] of Object.entries(data)) {
        this.schedule.push(    { day:key, hours:value },
      )
      }
    }, (err)=>{
      //assigining local data if api fails
      this.schedule=this.workingHours
    })

  }

}
