<div class="section testimonials">
    <div class="px-3">
        <div class="theme-container">
            <h1 class="section-title">What people are saying</h1> 
            <div class="testimonials-carousel mt-5"> 
                <div class="swiper-container h-100" [swiper]="config"> 
                    <div class="swiper-wrapper h-100">      
                        <div *ngFor="let testimonial of testimonials" class="swiper-slide">
                            <div class="content text-center">
                                <!-- <img [attr.data-src]="testimonial.image" class="swiper-lazy"> -->
                                <a *ngIf="testimonial.fields.review_source === 'GBR'" href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x47d8a71c3d1efde7:0x87110a0c2ece69c9?source=g.page.m.we._" target="_blank">
                                <img src="../../../assets/images/review.png" class="swiper-lazy">
                                </a> 
                                <a *ngIf="testimonial.fields.review_source === 'IA' " href="https://www.indianaroma.co.uk/" >
                                    <img src="../../../assets/images/Group 356.svg" class="swiper-lazy">
                                    </a> 
                               

                                <div class="quote open text-left primary-color">“</div>
                                <p class="text">{{testimonial.fields.comments}}</p>
                                <div class="quote close text-right primary-color">”</div> 
                                <h2 class="secondary-font primary-color">{{testimonial.fields.name}}</h2>
                                
                                <div class="cont">
                                    <div class="stars">
                                        <ngx-star-rating  [(ngModel)]="testimonial.fields.rating" [id]="'{{testimonial.fields.rating}}'"></ngx-star-rating>
                                    </div>
                                  </div>
                                <!-- <div class="swiper-lazy-preloader"></div> -->
                            </div>
                        </div>
                    </div> 
                    <div class="swiper-pagination"></div> 
                </div>
            </div> 
        </div>
    </div>   
</div> 