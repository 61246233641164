<div class="p-3">
    <div class="theme-container">
  
        <div class="my-4">
            <div fxLayout="column" fxLayoutAlign="center center" class="text-center">  
                <h1 class="secondary-font">Sitemap</h1>             
                
            </div>
  
            <div fxLayout="row" fxLayoutAlign="center center" class="my-3">
                <mat-card class="p-5">
                    <ul class="list">
                        <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/">Home</a></li>
                        <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/menu">Menu</a></li>
                        
                        <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/reservation">Book Table</a></li>
                        <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="">Policies</a></li>
                        <ul>
                            <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/privacy-policy">Privacy Policies</a></li>
                            <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/cookie-policy"></a>Cookie Policies</li>
                            <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/refund-policy"></a>Refund Policies</li>
                            <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/terms-conditions"></a>Tems and Conditions</li>
                        </ul>
                        <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/gallery">Gallery</a></li>
                        <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/about">About Us</a></li>
                        <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/chefs">Our chefs</a></li>
                        <li class="nav-item"><a class="nav-link" routerLinkActive="active" routerLink="/contact">Contact Us</a></li>
                        <li class="nav-item"><a class="nav-link" routerLinkActive="active" href="">Social Media</a></li>
                        <ul>
                            <li class="nav-item"><a class="nav-link" routerLinkActive="active" href="https://wa.me/+447916507777" target="_blank">Whatsapp</a></li>
                            <li class="nav-item"><a class="nav-link" routerLinkActive="active" href="https://www.facebook.com/indianaromalondon" target="_blank">Facebook</a></li>
                            <li class="nav-item"><a class="nav-link" routerLinkActive="active" href="https://www.instagram.com/indian_aroma_london/" target="_blank">Instagram</a></li>
                            <li class="nav-item"><a class="nav-link" routerLinkActive="active" href="https://www.youtube.com/channel/UCc4bUg_a2W-7W_gkYiqEgRg" target="_blank">Youtube</a></li>
                            <li class="nav-item"><a class="nav-link" routerLinkActive="active" href="https://twitter.com/IndianAroma_LDN" target="_blank">Twitter</a></li>
                            <li class="nav-item"><a class="nav-link" routerLinkActive="active"  href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x47d8a71c3d1efde7:0x87110a0c2ece69c9?source=g.page.m.we._" target="_blank">Google</a></li>
                            <li class="nav-item"><a class="nav-link" routerLinkActive="active" href="https://www.tripadvisor.co.uk/Restaurant_Review-g580420-d10331967-Reviews-Indian_Aroma_Restaurant-Ilford_Greater_London_England.html" target="_blank">Trip Advisor</a></li>
                        </ul>
                    </ul>
                </mat-card>
            </div>
            
        </div>
  
    </div>
  </div>

