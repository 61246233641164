<mat-sidenav-container>
  <mat-sidenav
    [opened]="false"
    mode="over"
    #sidenav
    class="sidenav mat-elevation-z6"
  >
    <button
      mat-icon-button
      color="warn"
      class="close"
      (click)="sidenav.close()"
    >
      <mat-icon color="warn">close</mat-icon>
    </button>
    <div class="divider"></div>

    <div  class="vertical-menu-wrapper">
      <app-vertical-menu [menuParentId]="0"></app-vertical-menu>
      <div class="text-center">
        <div class="d-flex">
          <a
             [href]="socialLink.whatsAppLink"
            target="_blank"
            class="social-icon mr-1"
          >
            <img
              src="assets/images/micons/png/whatsapp.png"
              class="image_social mr-1"
            />
          </a>
          <a
             [href]="socialLink.facebookLink"
            target="_blank"
            class="social-icon mr-1"
          >
            <img
              src="assets/images/micons/png/facebook.png"
              class="image_social mr-1"
            />
          </a>
          <a
               [href]="socialLink.instagramLink"
            target="_blank"
            class="social-icon mr-1"
          >
            <img
              src="assets/images/micons/png/insta.png"
              class="image_social mr-1"
            />
          </a>
          <a
             [href]="socialLink.tripAdvisorLink"
            target="_blank"
            class="social-icon mr-1"
          >
            <img
              src="assets/images/micons/png/tripadvisor.png"
              class="image_social mr-1"
            />
          </a>
          <a href="https://twitter.com/IndianAroma_LDN" target="_blank" class="social-icon "> 
                    <img src="../../assets/images/micons/twitter.svg" class="image_social mr-1">
                  </a> 
          <a
             [href]="socialLink.youtubeLink"
            target="_blank"
            class="social-icon mr-1"
          >
            <img
              src="assets/images/micons/png/youtube.png"
              class="image_social mr-1"
            />
          </a>
          <a
             [href]="socialLink.googleLink"
            target="_blank"
            class="social-icon"
          >
            <img
              src="assets/images/micons/png/googleplus.png"
              class="image_social mr-1"
            />
          </a>
          <a
             [href]="socialLink.printest"
            target="_blank"
            class="social-icon"
          >
            <img
              src="assets/images/micons/png/pin.png"
              class="image_social mr-1" style="    width: 32px;
              padding-bottom: 7px;"
            />
          </a>
          <a
          [href]="socialLink.linkedin"
         target="_blank"
         class="social-icon"
       >
         <img
           src="assets/images/icons/social/linkdin.png"
           class="image_social mr-1" style="    width: 32px;
           padding-bottom: 7px;"
         />
       </a>
        </div>
        <div class="divider theme"></div>

        <p class="mx-2 fwb">
          <a
            href="https://www.google.com/maps/place/Indian+Aroma/@51.5775013,0.0647723,17z/data=!3m1!4b1!4m5!3m4!1s0x47d8a71c3d1efde7:0x87110a0c2ece69c9!8m2!3d51.5775013!4d0.0647723"
            target="_blank"
          >
            <mat-icon color="primary">location_on</mat-icon><span class="mb-4">33 Woodford Avenue
              Gants Hill, Ilford IG2 6UF</span></a
          >
        </p>
        <p class="mx-2 fwbr">
          <mat-icon color="primary" class="mat-icon-lg">call</mat-icon>
          <span><a href="tel:+442085507777">(+44) 0 208 550 7777</a> </span><br>
          <mat-icon color="primary" class="mat-icon-lg">call</mat-icon><span><a href="tel:+447916507777">(+44) 0 791 650 7777</a> </span>
        </p>
        <div class="d-flex">
          <a class="mr-1">
            <img
              src="assets/images/micons/png/Group 207.png"
              class="image_social1 mr-1 w-50"
            />
          </a>
          <a>
            <img
              src="assets/images/micons/png/Group 208.png"
              class="image_social1 mr-1 w-50"
            />
          </a>
        </div>
      </div>
    </div>

    <div class="divider"></div>
  </mat-sidenav>

  <mat-sidenav-content
    class="page-wrapper"
    fxLayout="column"
    fxLayoutAlign="start stretch"
  >
    <header
      [ngClass]="'toolbar-' + settings.toolbar"
      [class.has-bg-image]="settings.headerBgImage"
      [class.has-bg-video]="settings.headerBgVideo"
      [class.main-toolbar-fixed]="settings.mainToolbarFixed"
    >
      <app-toolbar1
        *ngIf="settings.toolbar == 1"
        (onMenuIconClick)="sidenav.toggle()"
      ></app-toolbar1>
    </header>

    <main [class.content-offset-to-top]="settings.contentOffsetToTop">
      <router-outlet></router-outlet>
    </main>

    <div class="sticky-footer-helper" fxFlex="grow"></div>

    <app-footer></app-footer>

    <div class="options-icon">
      <a [href]="socialLink.whatsAppLink" target="_blank" class="social-icon "> 
        <img src="../../../../assets/images/icons/footer icons/whtsapp.svg" class="w-100">
      </a>
      <a [href]="socialLink.facebookLink"  target="_blank" class="social-icon ">
        <img src="../../../../assets/images/icons/footer icons/facebook.svg" class="w-100">
      </a>
      <a [href]="socialLink.instagramLink" target="_blank" class="social-icon ">
        <img src="../../../../assets/images/icons/footer icons/insta.svg" class="w-100">
      </a>
      <a [href]="socialLink.tripAdvisorLink" target="_blank" class="social-icon "> 
        <img src="../../../../assets/images/icons/footer icons/tripad.svg" class="w-100">
      </a>
      <a [href]="socialLink.youtubeLink" target="_blank" class="social-icon "> 
        <img src="../../../../assets/images/icons/footer icons/youtube.svg" class="w-100">
      </a> 
      <a href="https://twitter.com/IndianAroma_LDN" target="_blank" class="social-icon "> 
        <img src="../../../../assets/images/icons/footer icons/twitter.svg" class="w-100">
      </a> 
      <a [href]="socialLink.googleLink" target="_blank" class="social-icon"> 
        <img src="../../../../assets/images/icons/footer icons/googleplus.svg" class="w-100">
      </a> 
      <a [href]="socialLink.printest" target="_blank" class="social-icon"> 
        <img src="../../../../assets/images/icons/footer icons/printest.svg" style="width: 24px;
        margin-left: 6px;">
      </a> 
      <a [href]="socialLink.linkedin" target="_blank" class="social-icon"> 
        <img src="../../../../assets/images/icons/footer icons/linkdin.png" style="width: 24px;
        margin-left: 6px; margin-top: 12px">
      </a> 
  </div>

    <div
      *ngIf="showBackToTop"
      fxLayout="row"
      fxLayoutAlign="center center"
      class="back-to-top"
      (click)="scrollToTop()"
    >
      <mat-icon>arrow_upward</mat-icon>
    </div>
  </mat-sidenav-content>

  <mat-sidenav #options position="end" class="options" autoFocus="false">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="op-header mat-elevation-z1"
    >
      <h2>Settings</h2>
      <button mat-icon-button (click)="options.toggle()" color="accent">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div >
      <div fxLayout="column" class="control">
        <h4>Layout</h4>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span>Sticky Menu Toolbar</span>
          <mat-slide-toggle
            [checked]="settings.stickyMenuToolbar"
            (change)="settings.stickyMenuToolbar = !settings.stickyMenuToolbar"
            labelPosition="before"
          ></mat-slide-toggle>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span>RTL</span>
          <mat-slide-toggle
            [checked]="settings.rtl"
            (change)="settings.rtl = !settings.rtl"
            labelPosition="before"
          ></mat-slide-toggle>
        </div>
      </div>

      <div fxLayout="column" class="control">
        <h4>Choose header type</h4>
        <mat-radio-group
          [(ngModel)]="headerTypeOption"
          (change)="chooseHeaderType()"
        >
          <mat-radio-button
            *ngFor="let headerType of headerTypes"
            [value]="headerType"
            >{{ headerType }}</mat-radio-button
          >
        </mat-radio-group>
      </div>

      <div fxLayout="column" class="control">
        <h4>Choose theme skin</h4>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="skin-primary red" (click)="changeTheme('red')"></div>
          <div class="skin-primary blue" (click)="changeTheme('blue')"></div>
          <div class="skin-primary green" (click)="changeTheme('green')"></div>
          <div class="skin-primary pink" (click)="changeTheme('pink')"></div>
          <div
            class="skin-primary purple"
            (click)="changeTheme('purple')"
          ></div>
          <div class="skin-primary grey" (click)="changeTheme('grey')"></div>
          <div
            class="skin-primary orange-dark"
            (click)="changeTheme('orange-dark')"
          >
            <div class="skin-secondary"></div>
          </div>
        </div>
      </div>

      <!-- <div fxLayout="column" class="control">
                <h4>Choose search panel variant</h4>
                <mat-radio-group [(ngModel)]="searchPanelVariantOption" (change)="chooseSearchPanelVariant()">
                    <mat-radio-button *ngFor="let searchPanelVariant of searchPanelVariants" [value]="searchPanelVariant">variant-{{searchPanelVariant}}</mat-radio-button>
                </mat-radio-group>
                <mat-slide-toggle [checked]="settings.searchOnBtnClick" (change)="settings.searchOnBtnClick = !settings.searchOnBtnClick" labelPosition="after">Search On Button Click</mat-slide-toggle>
            </div>  -->
    </div>
  </mat-sidenav>
</mat-sidenav-container>
