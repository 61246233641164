<h3 class="chose ml-2 mt-3">1. CHOOSE ORDER TYPE</h3>
<mat-button-toggle-group
  class="main w-100 mt-3"
  [value]="selectedState"
  (change)="onChange($event)"
>
  <mat-button-toggle class="w-100 btndata" [value]="toggleEnum.Option1"
    >DELIVERY</mat-button-toggle
  >
  <mat-button-toggle class="w-100 btndata" [value]="toggleEnum.Option2"
    >PICKUP</mat-button-toggle
  >
</mat-button-toggle-group>

<div *ngIf="selectedState == 0" class="mt-3">
  <form
    [formGroup]="form"
    (ngSubmit)="submit()"
    fxLayout="row wrap"
    class="mt-3"
    autocomplete="off"
  >
    <h3 class="chose ml-1">2. ENTER YOUR POST CODE</h3>
    <div fxFlex="100" class="px-3 mt-3">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Type your postcode here</mat-label>
        <input
          matInput
          [(ngModel)]="enterPostcode"
          placeholder="Enter Full Postcode"
          formControlName="postCode"
          required
          (ngModelChange)="changeCode($event)"
          pattern="^.*$"
        />
        <mat-icon matSuffix class="text-muted" href="">map</mat-icon>
      </mat-form-field>
      <small class="mat-error" *ngIf="erorrMessage"
        >Postcode is required.</small
      >
      <small class="mat-error" *ngIf="inValidCode">Postcode is invalid.</small>
      <small class="mat-error" *ngIf="isNotMessage"
        >Sorry We don't deliver to your area, but you can call our restaurant to
        see if we can make an exception for you.
      </small>
    </div>
    <div class="but text-center mt-3 ml-2 mr-2 w-100">
      <button
        mat-raised-button
        color="primary"
        type="submit"
        class="uppercase w-100"
      >
        Proceed Order Online
      </button>
    </div>
  </form>
</div>

<div *ngIf="selectedState == 1" class="mt-3 mb-3">
  <div class="text-center mt-3 ml-2 mr-2">
    <button
      mat-raised-button
      color="primary"
      (click)="online()"
      class="uppercase w-100"
    >
      Proceed Order Online
    </button>
  </div>
</div>
<div class="reservation-form-footer mt-3">
  <p class="m-0">
    You can also call us
    <a href="tel:+442085507777">
      <mat-icon color="primary" class="mat-icon-lg">call</mat-icon>(+44) 0 208
      550 7777</a
    >
    or
    <a href="tel:+447916507777">
      <mat-icon color="primary" class="mat-icon-lg">call</mat-icon>(+44) 0 791
      650 7777</a
    >
    to place the order.
  </p>

  <p>
    Our Location:
    <mat-icon color="primary" class="mat-icon-sm mr-1 ml-1">home</mat-icon>

    <a href="https://goo.gl/maps/JuVK89DfJqGLLjh18" target="_blank"
      >33 Woodford Avenue Gants Hill, Ilford IG2 6UF England</a
    >
  </p>
  <p>
    Opening Times:
    <mat-icon color="primary" class="mat-icon-sm mr-1 ml-1">schedule</mat-icon>
    Mon-Sun: 5 PM - 10:30 PM.
  </p>
</div>
