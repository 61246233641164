import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
const Base_URL = 'https://2a3sde3.blog-indianaroma.co.uk';
// const Base_URL = 'http://localhost:5050';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  public UserDetails = new BehaviorSubject<any>(null);
  accessToken = '';

  private cartOpenSource = new Subject<any>();

  cartOpened = this.cartOpenSource.asObservable();

  constructor(private http: HttpClient) {

  }


  openCart() {
    this.cartOpenSource.next(1);
  }

   public  getMarketStalls(): Observable<any> {
    return this.http.get(`${Base_URL}/ia/marketstalls/`)
  }

  public getRemainingCouponCount(): Promise<any> {
    return this.http.get(`${Base_URL}/coupons/remaining`).toPromise();
  }


  registerCustomer(data: any): Promise<any> {
    return this.http.post(`${Base_URL}/customer/register`, data).toPromise();
  }

  registerCustomerForCoupon(data: any): Promise<any> {
    return this.http.post(`${Base_URL}/coupons/create`, data).toPromise();
  }


}
