<div class="section timeline pb-0">
    <div class="px-3">
        <div class="theme-container"> 
            <h1 class="section-title mb-3">Our Social Media Channels</h1>
            <div fxLayout="row wrap" class="services-wrapper">
                <div *ngFor="let time of times" fxFlex="100" fxFlex.gt-md="14" fxFlex.gt-xs="50" class="p-2"> 
                    <div fxLayout="column" fxLayoutAlign="start center" class="h-100 w-100 text-center">  
                        <!-- <span class="timeline-icon primary-color" [ngClass]="'flaticon-'+time.icon"></span>    -->
                        <a [href]="time.link"  target="_blank"><span class="ic"><img [src]="time.icon" style="width: 80px;"></span></a>
                        <a [href]="time.link"  target="_blank"><span class="ic"><h1 class="secondary-font capitalize fw-600 my-3">{{time.title}}</h1></span></a>
                        <a [href]="time.link"  target="_blank"><span class="ic"><h3 class="fw-500">{{time.hours}}</h3></span></a>
                        
                                   
                    </div> 
                </div>  
            </div> 
        </div>
    </div>   
</div>

 