import { AppService } from 'src/app/app.service';
import { Component, OnInit } from '@angular/core';
import { AppSettings, Settings } from 'src/app/app.settings';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  public workingHours = [
    { day: "MON", hours: "17:00:00 - 23:00:00" },
    { day: "TUE", hours: "17:00:00 - 23:00:00" },
    { day: "WED", hours: "17:00:00 - 23:00:00" },
    { day: "THU", hours: "17:00:00 - 23:00:00" },
    { day: "FRI", hours: "17:00:00 - 23:00:00" },
    { day: "SAT", hours: "17:00:00 - 23:00:00" },
    { day: "SUN", hours: "17:00:00 - 23:00:00" }
  ]


  schedule:any[]=[]
  // {"MON": "17:00:00 - 22:00:00", "TUE": "CLOSED", "WED": "17:00:00 - 22:00:00", "THU": "17:00:00 - 22:00:00", "FRI": "17:00:00 - 22:00:00", "SAT": "17:00:00 - 22:00:00", "SUN": "17:00:00 - 22:00:00"}
  public settings: Settings;
  constructor(public appSettings:AppSettings, private appService: AppService) { 
    this.settings = this.appSettings.settings; 
  }

  ngOnInit(): void { 
    this.getResturantSchedule()
  }

  getResturantSchedule(){
    this.appService.getResturantSchedule().subscribe((data)=>{
      for (const [key, value] of Object.entries(data)) {
        this.schedule.push(    { day:key, hours:value },
      )
      }
    }, (err)=>{
      //assigining local data if api fails
      this.schedule=this.workingHours
    })

  }
}
