<div class="p-3">
    <div class="theme-container">
  
        <div class="my-4">
            <div fxLayout="column" fxLayoutAlign="center center" class="text-center">  
                <h1 class="secondary-font">Terms and Conditions</h1>             
                <p>In using this website you are deemed to have read and agreed to the following terms and conditions:</p>
            </div>
  
            <div fxLayout="row" fxLayoutAlign="center center" class="my-3">
                <mat-card class="p-5">
    
                    <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any
                        or all Agreements: "Client", “You” and “Your” refers to you, the person accessing this website and accepting the
                        Company’s terms and conditions. "The Company", “Ourselves”, “We” and "Us", refers to our Company. “Party”,
                        “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer,
                        acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in
                        the most appropriate manner, whether by formal meetings of a fixed duration, or any other means, for the express
                        purpose of meeting the Client’s needs in respect of provision of the Company’s stated services/products, in
                        accordance with and subject to, prevailing English Law. Any use of the above terminology or other words in the
                        singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>
        
                    <h3>Privacy Statement</h3>
                    <p>We are committed to protecting your privacy. Authorized employees within the company on a need to know basis 
                        only use any information collected from individual customers. We constantly review our systems and data to ensure 
                        the best possible service to our customers. Parliament has created specific offences for unauthorised actions against 
                        computer systems and data. We will investigate any such actions with a view to prosecuting and/or taking civil 
                        proceedings to recover damages against those responsible </p>
        
                    <h3>Disclaimer, Exclusions and Limitations </h3>
        
                    <p>The information on this web site is provided on an "as is" basis. To the fullest extent permitted by law, this Company: </p>
                    <ul class="px-3">
                        <li>excludes all representations and warranties relating to this website and its contents or which is or may be 
                            provided by any affiliates or any other third party, including in relation to any inaccuracies or omissions in 
                            this website and/or the Company’s literature; and</li>
                        <li>excludes all liability for damages arising out of or in connection with your use of this website. This includes, 
                            without limitation, direct loss, loss of business or profits (whether or not the loss of such profits was 
                            foreseeable, arose in the normal course of things or you have advised this Company of the possibility of 
                            such potential loss), damage caused to your computer, computer software, systems and programs and the 
                            data thereon or any other direct or indirect, consequential and incidental damages.</li>    
                    </ul>
                    <p>This Company does not however exclude liability for death or personal injury caused by its negligence. The above 
                        exclusions and limitations apply only to the extent permitted by law. None of your statutory rights as a consumer are 
                        affected.</p>
        
                    <h3>Cancellation Policy</h3>
                    <p>Minimum 24 hours notice of cancellation required. Notification for instance, in person, via email, mobile phone ‘text 
                        message’ and/or fax, or any other means will be accepted subject to confirmation in writing. We reserve the right to 
                        levy a $30 charge to cover any subsequent administrative expenses. </p>
                    
                    <h3>Termination of Agreements and Refunds Policy</h3>
                    <p>Both the Client and ourselves have the right to terminate any Services Agreement for any reason, including the 
                        ending of services that are already underway. No refunds shall be offered, where a Service is deemed to have begun 
                        and is, for all intents and purposes, underway. Any monies that have been paid to us which constitute payment in 
                        respect of the provision of unused Services, shall be refunded. </p>
        
                    <h3>Availability</h3>
                    <p>Unless otherwise stated, the services featured on this website are only available within the USA, or in 
                        relation to postings from the USA. All advertising is intended solely for the USA market. You 
                        are solely responsible for evaluating the fitness for a particular purpose of any downloads, programs and text 
                        available through this site. Redistribution or republication of any part of this site or its content is prohibited, including 
                        such by framing or other similar or any other means, without the express written consent of the Company. The 
                        Company does not warrant that the service from this site will be uninterrupted, timely or error free, although it is 
                        provided to the best ability. By using this service you thereby indemnify this Company, its employees, agents and 
                        affiliates against any loss or damage, in whatever manner, howsoever caused. </p>
        
                    <h3>Cookies</h3>
                    <p>Like most interactive web sites this Company’s website [or ISP] uses cookies to enable us to retrieve user details for 
                        each visit. Cookies are used in some areas of our site to enable the functionality of this area and ease of use for 
                        those people visiting. Some of our affiliate partners may also use cookies.  </p>
        
                    <h3>Copyright Notice </h3>
                    <p>Copyright and other relevant intellectual property rights exists on all text relating to the Company’s services and the 
                        full content of this website.  
                        This Company’s logo is a registered trademark of this Company in the United Kingdom and other countries. The 
                        brand names and specific services of this Company featured on this web site are trade marked.</p>
        
                    <h3>Force Majeure</h3>
                    <p>Neither party shall be liable to the other for any failure to perform any obligation under any Agreement which is due to 
                        an event beyond the control of such party including but not limited to any Act of God, terrorism, war, Political 
                        insurgence, insurrection, riot, civil unrest, act of civil or military authority, uprising, earthquake, flood or any other 
                        natural or man made eventuality outside of our control, which causes the termination of an agreement or contract 
                        entered into, nor which could have been reasonably foreseen. Any Party affected by such event shall forthwith inform 
                        the other Party of the same and shall use all reasonable endeavours to comply with the terms and conditions of any 
                        Agreement contained herein.  </p>
        
                    <h3>Notification of Changes</h3>
                    <p>The Company reserves the right to change these conditions from time to time as it sees fit and your continued use of 
                        the site will signify your acceptance of any adjustment to these terms. If there are any changes to our privacy policy, 
                        we will announce that these changes have been made on our home page and on other key pages on our site. If there 
                        are any changes in how we use our site customers’ Personally Identifiable Information, notification by e-mail or postal  
                        mail will be made to those affected by this change. Any changes to our privacy policy will be posted on our web site 
                        30 days prior to these changes taking place. You are therefore advised to re-read this statement on a regular basis </p>
        
                    <h3>These terms and conditions form part of the Agreement between the Client and ourselves. Your accessing of 
                        this website and/or undertaking of a booking or Agreement indicates your understanding, agreement to and 
                        acceptance, of the Disclaimer Notice and the full Terms and Conditions contained herein. Your statutory 
                        Consumer Rights are unaffected.</h3>
        
                    <p class="text-right">© Rad sun Technology Limited 2022 All Rights Reserved</p>
    
                </mat-card>
            </div>
            
        </div>
  
    </div>
  </div>