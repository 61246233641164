<mat-toolbar id="main-toolbar" class="mat-elevation-z2" (scroll)="scrollHandler($event)" [ngClass]=" flag ? 'buttonnav-t' : 'secondary'"> 
  <div class="theme-container" fxLayout="row" fxLayoutAlign="space-between flex-start">
    <div fxLayout="row" class="menu">
      <span fxHide="false" fxShow.gt-lg class="menu-list">
        <button mat-icon-button (click)="sidenavToggle()">
          <mat-icon class="mat-icon-lg">menu</mat-icon>
        </button> 
      </span>
      <a class="logo" routerLink="/" (click)="sidenavToggle()">
        <div class="logo"> 
          <img src="../../../assets/images/pot.jpg" class="logod">
        </div>
      </a>  
    </div>
    <!-- <div fxShow="false" fxShow.gt-sm class="horizontal-menu">            
        <app-horizontal-menu [menuParentId]="0"></app-horizontal-menu>
    </div>  -->
    <div class="menu">
      <!-- <button (click)="openCart()" mat-icon-button matTooltip="Order Online">
        <mat-icon [matBadge]="appService.Data.totalCartCount" 
                  [matBadgeHidden]="appService.Data.totalCartCount == 0" 
                  matBadgeSize="small" 
                  matBadgeColor="warn">shopping_cart</mat-icon>
      </button>  -->
      <button (click)="openCart()" class="order_online" mat-raised-button [matTooltip]="'Order Online' | translate" fxShow="true" fxShow.gt-xs="true">
        Order Online
      </button> 
      <button routerLink="/reservation" class="book_now"  mat-raised-button [matTooltip]="'Book Table' | translate" fxShow="true" fxShow.gt-xs="true">
        Book Table
      </button><br>
      <!-- <div class="alert alert-info fade in alert-dismissible" style="margin-top:18px;" *ngIf="enableAlert">
        <div><img src="../../../assets/images/pot.jpg" class="poti"> </div>
        <div style="margin-left: 35px;">Over<strong> {{data.total_orders_today}} views </strong> in  24 hours</div>
        <div><a href="#" class="close" data-dismiss="alert" aria-label="close" title="close" style="font-size: 24px;">×</a></div>
        <app-onloadPopup style="width: 500px;"></app-onloadPopup>
      </div> -->
    </div>  
  </div>  
</mat-toolbar>