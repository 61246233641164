import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';
import { emailValidator, phnNumValidator } from 'src/app/theme/utils/app-validators';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {
  public form!: UntypedFormGroup;
  ratinginvalid: boolean =false;
  currentRate = 0;
  rating3 : number = 0;
  @Output() onFormSubmit: EventEmitter<any> = new EventEmitter(); 
  feedbackForm!: UntypedFormGroup;
  stars:Array<string> = [];

  @ViewChild('fbform') fbform:any;
  constructor(public formBuilder: UntypedFormBuilder,
    public router:Router,
    public service: AppService) { }

  ngOnInit(): void {
    this.feedbackForm = this.formBuilder.group({ 
      name: [null, Validators.required],
      email: [''],
      contact_no:["",phnNumValidator],
      rating:[null,Validators.required] ,
      feedback: [null, Validators.required]
  });
  }


  change(event:any){
    this.rating3 = event;
    this.ratinginvalid = false;
  }
  public submit(){
    if(this.form.valid){
      this.onFormSubmit.emit(this.form);
    }
  }

  onRateChange(rating :number) {
        this.ratinginvalid = false;
    }

  public onFeedbackFormSubmit(values:Object):void {
      if(this.feedbackForm.get('rating')?.value == 0 || this.feedbackForm.get('rating')?.value == null){
        this.ratinginvalid = true;
      } else {
        this.service.getData(values);
        this.router.navigateByUrl('/thank-you-feedback');
        this.feedbackForm.get('name')?.setValue('');
        this.feedbackForm.get('email')?.setValue('');
        this.feedbackForm.get('contact_no')?.setValue('');
        this.feedbackForm.get('rating')?.setValue(null);
        this.feedbackForm.get('feedback')?.setValue('');


        this.fbform.resetForm();

      }
}

}
