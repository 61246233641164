<div class="px-3">
    <div class="theme-container">  
        <div class="get-in-touch bg-primary" ngClass.lt-md="mt-3"> 
            <img class="caterimg" src="../../../assets/images/others/staff.png" alt="operator" fxShow="true" fxShow.gt-xs> 
            <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="content" ngClass.xs="p-0"> 
                <div fxLayout="column" class="py-2 px-3">
                    <h1 class="secondary-font">Looking for Catering Services ?</h1>
                    
                </div>
                <div fxLayout="row" fxLayoutAlign="start center" class="py-2 px-3">
                    <mat-icon class="mat-icon-xlg mx-2">call</mat-icon>
                    <div fxLayout="column">               
                        <p class="mb-0">CALL US NOW</p>
                        <h2 class="ws-nowrap"><a href="tel:+442085507777">(+44) 208 550 7777</a></h2>
                    </div>
                </div>
                <div class="py-2 px-3">
                    <a mat-flat-button href="https://www.indianaromacaterers.co.uk/"  target="_blank" mx-3 uppercase>GET IN TOUCH</a>
                </div>
            </div>  
        </div> 
    </div>
</div>