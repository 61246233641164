<div class="section mt-3">
    <div class="px-3">
        <div class="theme-container">
            <h1 class="section-title mb-3">Award Winning Indian Restaurant & Takeaway in Gants Hill, Ilford</h1>
            <p class="section-desc text-muted">Indian Aroma Restaurant is the best restaurant in the country and has been recognized with several awards in 2021-2023. We uphold our oath to give our customers authentic Indian dishes and an outstanding service every time.</p>
  
            <div class="awards-carousel"> 
                <div class="swiper-container h-100" [swiper]="config"> 
                    <div class="swiper-wrapper h-100">      
                        <div *ngFor="let award of awards" class="swiper-slide">
                            <div class="award-item"> 
                                <img [attr.data-src]="award.image" class="swiper-lazy"> 
                                <div class="swiper-lazy-preloader"></div>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
                
        </div>
    </div>   
  </div>