// phone numbers
export const landPhone = {
    label: "(+44) 0 208 550 7777",
    value: "+442085507777",
  };
  
  export const mobilePhone = {
    label: "(+44) 0 791 650 7777",
    value: "+447916507777",
  };
  
  //address
  export const address ={
      label:'33 Woodford Avenue Gants Hill, Ilford IG2 6UF England',
      mapStreetViewLink:'https://goo.gl/maps/JuVK89DfJqGLLjh18'
  }
  
  // Emails
  
  export const Emails = {
      infoEmail : "info@indianaroma.co.uk",
      contactEmail : "contact@indianaroma.co.uk",
      bookingEmail : "bookings@indianaroma.co.uk"
  }
  
  // Social Links
  
  
  export const socialLinks = {
      whatsAppLink : "https://wa.me/+447916507777",
      instagramLink : "https://www.instagram.com/indian_aroma_london/",
      facebookLink : "https://www.facebook.com/indianaromalondon",
      googleLink : "https://www.google.com/maps/place//data=!4m2!3m1!1s0x47d8a71c3d1efde7:0x87110a0c2ece69c9?source=g.page.m.we._",
      tripAdvisorLink : "https://www.tripadvisor.co.uk/Restaurant_Review-g580420-d10331967-Reviews-Indian_Aroma_Restaurant-Ilford_Greater_London_England.html",
      youtubeLink : "https://www.youtube.com/channel/UCc4bUg_a2W-7W_gkYiqEgRg",
      printest:"https://www.pinterest.co.uk/indianaromalondon/",
      linkedin:'https://www.linkedin.com/company/indian-aroma-cuisine-limited/'
  }
  
  // Delivery Partners
  
  export const deliveryPartners = {
      deliverooLink : "https://deliveroo.co.uk/menu/london/barkingside/indian-aroma-restaurant-woodford-ave?geohash=u10j3bdme3fd",
      justEatLink : "https://www.just-eat.co.uk/restaurants-shri-bheemas-indian-restaurant-new-ilford-ig2/menu?adj_tracker=84l7irb&adj_campaign=CM_S_G_GBR_EN_%5BRES%5D_%5BENGM%5D_OD_London_1006886&gclid=Cj0KCQjwxveXBhDDARIsAI0Q0x02GrHQpBQivLOgR69OHCuIYNmno3bYIqJO52zRUqt18MIGG-67UakaAhI2EALw_wcB&gclsrc=aw.ds",
      uberEatsLink : "https://www.ubereats.com/gb/store/indian-aroma/xcoDm72tTJaTw5LPPrcqsQ"
  }
  
  
  
  
  
  
  