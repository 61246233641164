

<div class="p-3">  
  <div class="theme-container"> 
  
  <mat-card class="main-content-header p-0 mb-4"> 
  
  <div  fxLayout="column" fxLayoutAlign="center center" class="text-center py-5"> 
    <img src="../../../assets/images/warn.svg" alt="warn image"> 
    <h1 class="secondary-font mt-5">Sorry an error has occurred.</h1>
    <p>If the issue persists, Please try again and <a [routerLink]="['/contact']"> contact us.</a></p>  
    <a [routerLink]="['/']" mat-raised-button color="primary" class="mt-3">Go to Homepage</a> 
  </div>  
  
  > 
  
  </mat-card> 
  
  </div> 
  </div> 