import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { AppSettings, Settings } from 'src/app/app.settings';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit {
  public settings: Settings;
  constructor(
    public appService:AppService,
    public appSettings: AppSettings,
    ) { 
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
  }
  public onMakeReservation(event:any){ 
    this.appService.makeReservation(null, event.value, false);
  }

}
