import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { OrderOnlineComponent } from '../order-online/order-online.component';
@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent implements OnInit {

  constructor(public appService:AppService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  public openCart(){     
    this.appService.makeReservation(OrderOnlineComponent, null, true,);
  }
}
