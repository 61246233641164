// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: '',
  dev:'https://earrr2m5ha.execute-api.eu-west-2.amazonaws.com/test',  
  // dev:'https://8dk3pldyfg.execute-api.eu-west-2.amazonaws.com/dev',
  menuListApiUrl:'https://indianaroma.gonnaorder.com/api/v1/stores/3573/catalog?languageId=en'
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
