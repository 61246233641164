import { AppService } from 'src/app/app.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Toolbar1Component } from 'src/app/theme/components/toolbar1/toolbar1.component';

@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrls: ['./home-banner.component.scss']
})
export class HomeBannerComponent implements OnInit {


  bannerState:any;
  constructor(public appService: AppService,private dialogRef: MatDialogRef<Toolbar1Component>) { }

  ngOnInit(): void {

    this.bannerState = this.appService.getDialogStatus()
  }

  closeDialog() {
    //Write your stuff here
    this.dialogRef.close(); // <- Closes the dialog
  }
}
