import { Component, Inject, OnInit, AfterViewInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
  selector: 'app-image-viewer-dialog',
  standalone: true,
  imports: [MatIconModule, MatButtonModule],
  encapsulation: ViewEncapsulation.None,
  template: `
    <div class="flex" style="position: relative;">
    <button
      class="text-secondary"
      mat-dialog-close
      mat-icon-button
      style="position: absolute; top: 0; right: 0; z-index: 1;background: white; "
      (click)="dialogRef.close()"
      type="button">
      <mat-icon style="">close</mat-icon>

    </button>
      <img [src]="data.imgSrc" class="zoom-img" #zoomImg>
    </div>
  `,
  styles: [`
    .image-dialog-container {
      display: flex;
      flex-direction: column;
      height: 100vh;
      overflow: hidden;
    }
    .zoom-img {
      max-width: 100%;
      max-height: 100vh;
      transition: transform 0.25s ease;
    }
    .mat-dialog-container{
      padding: 5px !important;
    }
  `]
})
export class ImageViewerDialogComponent implements AfterViewInit {
  @ViewChild('zoomImg') zoomImg!: ElementRef;

  constructor(
    public dialogRef: MatDialogRef<ImageViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngAfterViewInit(): void {
    // Implementation for any post-view initialization
  }
}
