import { Menu } from './menu.model';

export const horizontalMenuItems = [ 
    new Menu (1, 'NAV.HOME', '/', null, null, false, 0),
    new Menu (1, 'Market Stall', '/market-stall', null, null, false, 0),
    new Menu (2, 'MENU', '/menu',null , null, false, 0),
    new Menu (10, 'BOOK TABLE', '/reservation',null,null, false, 0),

    new Menu (10, 'CATERING',null, 'https://www.indianaromacaterers.co.uk', '_blank', false,0),
    new Menu (11, 'GALLERY', '/gallery', null, null, false, 0),
    new Menu (11, 'PRESS', '/press', null, null, false, 0),  
    new Menu (60, 'Blog', null, 'https://www.blog-indianaroma.co.uk/', '_blank', false,0), 
    new Menu (80, 'OUR STORY', '/about', null, null, false, 0),    
    new Menu (70, 'CONTACT', '/contact', null, null, false, 0)   
]

export const verticalMenuItems = [ 
    new Menu (1, 'NAV.HOME', '/', null, null, false, 0),
    new Menu (1, 'Market Stall', '/market-stall', null, null, false, 0),
    new Menu (2, 'MENU', '/menu', null, null, false, 0), 
    new Menu (10, 'BOOK TABLE', '/reservation',null,null, false, 0),
    new Menu (69, 'Order Online', '/', null, '_blank', false,0),
    new Menu (10, 'CATERING',null, 'https://www.indianaromacaterers.co.uk', '_blank', false,0),
    new Menu (11, 'GALLERY', '/gallery', null, null, false, 0),
    new Menu (11, 'PRESS', '/press', null, null, false, 0),   
    new Menu (60, 'Blog', null, 'https://www.blog-indianaroma.co.uk/', '_blank', false,0), 
    new Menu (80, 'OUR STORY', '/about', null, null, false, 0),   
    new Menu (70, 'NAV.CONTACT', '/contact', null, null, false, 0)
]