import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-order-online',
  templateUrl: './order-online.component.html',
  styleUrls: ['./order-online.component.scss']
})
export class OrderOnlineComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<OrderOnlineComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  public onMakeReservation(event:any){
    console.log('event: ', event);
    this.dialogRef.close(event.value);
  }

  public selectCategory(id:number){
  }

}
