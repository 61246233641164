import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { OrderOnlineComponent } from 'src/app/shared/order-online/order-online.component';


@Component({
  selector: 'app-order-online-page',
  templateUrl: './order-online-page.component.html',
  styleUrls: ['./order-online-page.component.scss']
})
export class OrderonlinepageComponent implements OnInit {

  constructor(
    public appService: AppService
  ) { }

  ngOnInit(): void {
  }
  public openCart() {
    this.appService.makeReservation(OrderOnlineComponent, null, true);
  }
  
}
