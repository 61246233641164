<!-- <app-header-image [backgroundImage]="'../../../assets/images/others/gallery.mp4'" 
                  [bgImageAnimate]="false" 
                  [contentOffsetToTop]="false" 
                  [title]="'Gallery'">
</app-header-image> -->
<app-header-video  *ngIf="settings.header == 'video'"
                [backgroundVideo]="'https://s3.eu-west-2.amazonaws.com/ia.media.prod/images/gallery.mp4'"
                [contentOffsetToTop]="true"
                [title]="'Gallery'"
                
                [isHomePage]="false"
                [fullscreen]="false" style="height:10vh">
</app-header-video>

<div class="px-3">  
    <div class="theme-container"> 
        <mat-sidenav-container class="all-menu-items mt-3">
            <mat-sidenav #sidenav [autoFocus]="false" [opened]="sidenavOpen" [mode]="sidenavOpen ? 'side' : 'over'" class="search-sidenav">         
                <mat-card > 
                    <button fxHide="false" fxHide.gt-xs mat-icon-button (click)="sidenav.toggle()">
                        <mat-icon color="accent">close</mat-icon>
                    </button>

                    <div class="p-3">
                        <button mat-raised-button  class="uppercase w-100" (click)="selectCategory(0)">Restaurant Gallery</button>
                    </div>
                    <div class="p-3">
                        <button mat-raised-button  class="uppercase w-100" (click)="selectCategory(1)">Catering Gallery</button>
                    </div> 

                </mat-card> 
            </mat-sidenav>

            <mat-sidenav-content [class.distance]="sidenavOpen">
                <div fxLayout="row wrap" class="all-menu-items-wrapper mt-0">
                    <div fxFlex="100" fxLayoutAlign="center center" fxShow="true" fxShow.gt-sm class="px-2 mb-3">
                        <mat-button-toggle-group class="list-group" #group="matButtonToggleGroup" appearance="legacy" [value]="selectedCategoryId" class="categories-toogle-group mt-2" (change)="onChangeCategory($event)">
                            <mat-button-toggle [value]="0" class="letter" >
                                Restaurant Gallery
                            </mat-button-toggle>
                            <mat-button-toggle [value]="1" class="letter" >
                                Catering Gallery
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                 
               
                    <ng-container *ngIf="Res" >
                        <div *ngFor="let menuItem of ResmenuItems" [fxFlex]="(viewType=='grid') ? viewCol : 100"  class="item">  
                            <app-gallery-menu  [menuItem]="menuItem" [viewType]="viewType" [viewColChanged]="viewCol"></app-gallery-menu>
                            
                        </div>
                    </ng-container>
                    
                    <ng-container *ngIf="Cate">
                        <div class="reservation-form-footer">
                            <p>Our catering services are very unique. We provide a truly authentic Indian food for all occasions. We have an excellent team of trained chefs and staff members who will work with you in understanding your requirements, giving you options and delivering the best Indian food.</p>
                            <div fxLayout="row" fxLayoutAlign="center center">
                                <a mat-raised-button href="https://www.indianaromacaterers.co.uk" color="primary" class="uppercase">Explore our Catering</a>
                            </div>  

                        
                          <p>Our customers have been extremely happy with our services and the wide range of menu we offer.We have the top food hygiene rating that we are proud of and are continuing to uphold.</p>
                          <div fxLayout="row" fxLayoutAlign="center center">
                            <a mat-raised-button href="https://www.indianaromacaterers.co.uk/our-gallery" color="primary" class="uppercase">View Catering Gallery</a>
                        </div> 
                            <p>We are one of the very few Indian restaurants in the UK who has adopted recyclable packaging and have fully eliminated plastic materials.</p>

                         
                            
                          </div>
                    </ng-container>
                    
                    
        
                    <div *ngIf="menuItems && menuItems.length == 0" fxFlex="100" fxLayoutAlign="center center" [style.min-height.px]="200">                
                        <mat-spinner *ngIf="!message"></mat-spinner>
                        <mat-chip-listbox *ngIf="message">
                            <mat-chip selected disableRipple="true" color="warn" class="uppercase ws-nowrap">
                                {{message}}
                            </mat-chip> 
                        </mat-chip-listbox> 
                    </div>            
                </div>
            </mat-sidenav-content>
        </mat-sidenav-container> 
    </div>
</div>
<app-get-in-touch></app-get-in-touch>