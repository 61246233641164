import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AppService } from "src/app/app.service";
import { AppSettings } from "src/app/app.settings";
import { HomeBannerComponent } from "src/app/shared/home-banner/home-banner.component";
import { OrderOnlineComponent } from "src/app/shared/order-online/order-online.component";
import { ReservationDialogComponent } from "src/app/shared/reservation-dialog/reservation-dialog.component";
import { HttpClient } from '@angular/common/http';
import { OnloadPopupComponent } from "src/app/shared/onloadPopup/onloadPopup.component";
import { DataService } from "src/app/core/services/data.service";

@Component({
  selector: "app-toolbar1",
  templateUrl: "./toolbar1.component.html",
  styleUrls: ["./toolbar1.component.scss"],
})
export class Toolbar1Component implements OnInit {
  flag: boolean = false; 
  enableAlert = true;
  @Output() onMenuIconClick: EventEmitter<any> = new EventEmitter<any>();
  data: any;
  constructor(
    public appService: AppService,
    public dialog: MatDialog,
    public appSettings: AppSettings,
    public http: HttpClient,
    private dataService:DataService
  ) {}

  @HostListener("window:scroll", ["$event"])
  scrollHandler(event: any) {
    if (event.currentTarget.scrollY > 60) {
      this.flag = true;
    } else {
      this.flag = false;
    }
  }

  closedPopupState = this.appService.getDialogStatus()

  ngOnInit() {
    setTimeout(()=>{
      this.enableAlert = false;
    },10000);

   
      this.dataService.cartOpened.subscribe(() => {
        this.openCart();
      });
    

    this.appService.makeReservation(OnloadPopupComponent, null, true);

    if(this.closedPopupState.restaurantNotification == 'ON'){

      this.openDialog(HomeBannerComponent, null, 'theme-dialog')
    }

    
  }

  public sidenavToggle() {
    this.onMenuIconClick.emit();
  }

  public openDialog(component: any, data: any, panelClass: any) {
    return this.dialog.open(component, {
      data: data,
      panelClass: panelClass,
      autoFocus: false,
      direction: this.appSettings.settings.rtl ? "rtl" : "ltr",
    });
  }

  public openCart() {
    this.appService.makeReservation(OrderOnlineComponent, null, true);
  }
  public reservation() {
    this.appService.makeReservation(ReservationDialogComponent, null, true);
  }
}
