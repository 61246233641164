import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Settings, AppSettings } from './app.settings';
import { Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { DomHandlerService } from './dom-handler.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
   
  public settings: Settings;

  cookieMessage: any ='This website uses cookies and similar technologies to ensure you get the best experience on our website.We also store cookies to personalize the website content and to serve more relevant content to you.By Continuing to use the service, It will assume that you are agreeing to our privacy policy.';
  cookieDismiss: any = 'Agree';
  cookieLinkText: any = 'Learn More';

  constructor(public appSettings:AppSettings, 
              public router:Router,
              @Inject(PLATFORM_ID) private platformId: Object,
              public translate: TranslateService,
              private domHandlerService: DomHandlerService){
    this.settings = this.appSettings.settings;
    translate.addLangs(['en','de','fr','ru','tr']);
    translate.setDefaultLang('en'); 
    translate.use('en');
  }

  ngAfterViewInit(){ 
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {   
        this.domHandlerService.winScroll(0,0);
      }            
    }); 
    
    let cc = window as any;
       cc.cookieconsent.initialise({
         palette: {
           popup: {
             background: "#ffffff"
           },
           button: {
             background: "#b71c1c",
             text: "#ffffff"
           }
         },
         theme: "classic",
         content: {
           message: this.cookieMessage,
           dismiss: this.cookieDismiss,
           link: this.cookieLinkText,
           href: "/cookie-policy"
         }
       });
  }

}
