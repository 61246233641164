import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {
  id: any;

  constructor(public appService:AppService,
    private route: ActivatedRoute) { 
      this.route.queryParams.subscribe(param =>{
        this.id=param['id'];
    });
    }

  ngOnInit(): void {
     this.appService.getUnSubscription(this.id);
  }

}
