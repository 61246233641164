import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router'; 

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LockScreenComponent } from './pages/lock-screen/lock-screen.component';
import { GalleryCustomeComponent } from './pages/gallery-custome/gallery-custome.component';
import { ThankyouComponent } from './shared/thankyou/thankyou.component';
import { AllergyAdviceComponent } from './shared/allergy-advice/allergy-advice.component';
import { CookiePolicyComponent } from './shared/cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './shared/privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './shared/refund-policy/refund-policy.component';
import { TermsAndConditionComponent } from './shared/terms-and-condition/terms-and-condition.component';
import { NewsletterErrorComponent } from './shared/newsletter-error/newsletter-error.component';
import { NewsletterThankyouComponent } from './shared/newsletter-thankyou/newsletter-thankyou.component';
import { UnsubscribeComponent } from './shared/unsubscribe/unsubscribe.component';
import { ErrorpageComponent } from './shared/error-page/error-page.component';
import { ThankyouFeedbackComponent } from './shared/thank-you-feedback/thank-you-feedback.component';
import { SitemapComponent } from './shared/sitemap/sitemap.component';
import { MarketStallComponent } from './pages/market-stall/market-stall.component';
import { OrderonlinepageComponent } from './pages/order-online-page/order-online-page.component';
import { PressComponent } from './pages/press/press.component';
import { ReservationComponent } from './pages/reservation/reservation.component';
import { TermsConditionsComponent } from './pages/terms-conditions/terms-conditions.component';
import { MenuComponent } from './pages/menu/menu.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { BlogComponent } from './pages/blog/blog.component';

const routes: Routes = [
  { 
    path: '', 
    component: PagesComponent, children: [
        { path: 'gallery', component:GalleryCustomeComponent },
        { path: 'refund-policy', component:RefundPolicyComponent },
        { path: 'allergy-advice', component:AllergyAdviceComponent },
        { path: 'cookie-policy', component:CookiePolicyComponent },
        { path: 'privacy-policy', component:PrivacyPolicyComponent },
        { path: 'thank-you', component:ThankyouComponent },
        { path: 'thank-you-feedback', component:ThankyouFeedbackComponent},
        { path: 'error-page', component:ErrorpageComponent },
        { path: 'sitemap', component:SitemapComponent},
        { path: 'terms-of-service', component:TermsAndConditionComponent },
        { path: 'newsletter-error', component:NewsletterErrorComponent },
        { path: 'newsletter-thankyou', component:NewsletterThankyouComponent },
        {path:'unsubscribe',component:UnsubscribeComponent},
        { path: '', component:HomeComponent },
        {path:'market-stall',component:MarketStallComponent},
        { path: 'about', component:AboutComponent },
        { path: 'contact', component:ContactComponent },
        { path: 'menu', component:MenuComponent },
        { path: 'order-online-page', component:OrderonlinepageComponent },
        { path: 'press', component:PressComponent },
        { path: 'reservation', component:ReservationComponent },
        // { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule) },
        // { path: 'cart', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule) },
        // { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule) },
        // { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
        // { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
        // // { path: 'faq', loadChildren: () => import('./pages/faq/faq.module').then(m => m.FaqModule) },
        { path: 'terms-conditions', component:TermsConditionsComponent },
        { path: 'blog' ,component:BlogComponent},
    ]
  },
  // { path: 'landing', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingModule) },
  // { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) },
  // { path: 'lock-screen', component: LockScreenComponent },
  { path: '**', component: NotFoundComponent } 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    initialNavigation: 'enabledBlocking'
})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }