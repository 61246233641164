<div class="p-3">
    <div class="theme-container">
  
        <div class="my-4">
            <div fxLayout="column" fxLayoutAlign="center center" class="text-center">  
                <h1 class="secondary-font">Privacy Policy</h1>             
                <p>In using this website you are deemed to have read and agreed to the following Privacy Policies:</p>
            </div>
  
            <div fxLayout="row" fxLayoutAlign="center center" class="my-3">
                <mat-card class="p-5">
                    <div class="privacy-policy-content">
                        
                        <!-- <p><i>This Privacy Policy was last updated on January 1, 2020.</i></p> -->
                        <h3>General</h3>
                        <blockquote class="blockquote">
                            <p>Indian Aroma is committed to protecting and respecting your privacy. We will ensure that any data that you may provide to us is handled in accordance with the UK Data Protection Act, the General Data Protection Regulation and the Privacy and Electronic Communications Regulations.</p>
                            <p>This policy will further explain how we use any personal information that you provide to us through our website, any third-party services integrated with our site in terms of ordering and payment and information you may give us directly.  Please read the policy carefully to understand our practices concerning your data and how we treat it. By visiting indianaroma.co.uk and any other sites connected to this URL, you are accepting and consenting to the practices outlined in this policy</p>
                            <p>For the purposes of data protection law, the controller is Indian Aroma of 33 Woodford Avenue, Gants Hill IG2 6UF               </p>
                        </blockquote>
                        <h3>Information We May Collect From You</h3>
                        <p>We may collect and process the following information about you:</p>
                        <h3>i)Information you provide to us</h3>
                        <p>This is information that is provided to us by filling a form, contacting us through phone calls, emailing us or any other form of communication. It also includes any information that you may provide through any of our social media platforms linked on our site. This information may include your title, name, email address, phone number and address.</p>
                        <h3>ii)Information we collect about you</h3>
                        <p>During each visit to our website, certain technical information is automatically collected which includes the IP address of the device you are visiting the site from, the type of browser you are using and its version, operating system of your device and timezone settings. Furthermore, information about your visit (including how you got to our website) and how you use our services may also be collected including the url of the page you are on, click stream data to and from our site, length of visit and page interactions. We may combine this information with other information you have provided to us.</p>
                        <h3>iii)Information from other sources</h3>
                        <p>As we are working closely with third party service providers and have integrated their services to our site, we may on occasion obtain data from them as well. The other sources we may obtain your data from include any of the following:
                        </p>
                        <ol>
                            <li>Any of our other websites linked to this website.</li>
                            <li>Payment providers</li>
                            <li>Third party ordering services</li>
                        </ol>
                        <h3>How We Use Your Personal Information
                        </h3>
                        <p>We use your personal information for the following purposes:</p>
                        <h3>Providing our services to you and fulfilling your requests</h3>
                        <p>To fulfil our obligation to you as a customer in regards to any services provided or products ordered from us in a fast and efficient manner.</p>
                        <h4>Marketing and advertising</h4>
                        <ul>
                            <li>Providing you with information about products and special offers through newsletters via email if subscribed.</li>
                            <li>Providing services or recommending similar products based on information you may have requested or products you may have previously purchased.</li>
                            <li>To allow for your participation in any events or services should you decide to do so.
                            </li>
                         </ul>
                        <h3>Service improvement and Business Administration</h3>
                        <ul>
                            <li>To administer our website and to ensure that our content is presented in the most effective manner for you and your device.</li>
                            <li>For internal business administration (including but not limited to troubleshooting, data analysis, testing and research).</li>
                            <li>Protecting our interests and as part of our efforts to keep our services safe and secure.</li>
                            <li>To notify you of any changes to the services that we provide.</li>
                        </ul>
                        <h2>Legal Basis For Processing</h2>
                        <p>Our legal basis for processing your personal information for the purposes described above will typically be one of the following:</p>
                        <h3>Contract</h3>
                        <p>It is necessary for us to process your personal information to perform a contract to which you are a party, or to take steps at your request prior to you entering into a contract.</p>
                        <h3>Consent</h3>
                        <p>You have consented to us using your personal information for this purpose (for example, when you agree to receive marketing emails from us by subscribing to our newsletter). You can opt-out of this at any time by clicking on the unsubscribe link attached to the bottom of the newsletter we might send to you</p>
                        <h3>Legitimate Interests</h3>
                        <p>It is in our legitimate interests to use your personal information to operate, improve and promote our services (for example, when we customise your experience of our services or use your information for analytics purposes) and to protect our business.
                        </p>
                        <h3>Legal Obligations</h3>
                        <p>It is necessary for us to use your personal information for us to comply with a legal obligation.</p>
                        <h3>Sharing Your Personal Information</h3>
                        <p>We will never share, sell, divulge or otherwise knowingly give your personal information to any third parties except:</p>
                        <h3>Our service providers to help us run our business and perform services you request</h3>
                        <p>We may provide your personal information to our business partners, suppliers and subcontractors who provide services to us or where necessary to perform a service that you have requested, for example:</p>
                        <ul>
                            <li>Online credit/debit card payment processor:</li>
                            <li>If online payment is available and selected as your chosen method of payment, you will be transferred to our payment partner’s secure website with your billing name and address in order to complete the transaction and then returned to our confirmation page when the transaction is complete. We never collect or save credit or debit card details.</li>
                            <li>Delivery company:</li>
                            <li>If we use a third party service to deliver your online order we will pass your delivery name and address to them in order to fulfil our contract with you.</li>
                        </ul>
                        <h3>Analytics and search engine providers to help us improve our services</h3>
                        <p>We may provide information about your visit to analytics and search engine providers to help us improve and optimise our services. We generally only share this information in a form that does not directly identify you.</p>
                        <h3>To comply with a legal obligation</h3>
                        <p>We may disclose your personal information if required to do so by law or in response to a request from law enforcement or another regulatory authority. We may disclose your personal information to enforce our agreements with you or to protect the rights, property or safety of Indian Aroma, its users or others. This includes sharing personal information with other organisations for fraud prevention purposes.</p>
                        <h2>Storing Your Personal Information</h2>
                        <h3>Data Storage</h3>
                        <p>Any and all information you provided us will be stored on secure servers with appropriate access control. This data will only be accessible  to trained and trusted Indian Aroma employees. Any payment transactions made to us will be encrypted.
                        </p>
                        <p>With that being said, the security and integrity of information transferred via the internet cannot be completely assured. Despite us taking strict measures to protect your information, we cannot guarantee the security of data during the transmission process; any transmission of data via the internet is at your own risk. We will take strict measures to make sure to prevent unauthorised access to your data once it is in our possession.</p>
                       <h3>Data Retention</h3>
                        <p>Any data we may possess will be held and cleared in accordance with the GDPR rules.
                        </p>
                        <h1>Cookies</h1>
                        <p>Cookies are used to differentiate you from any other user that may visit our website. These cookies are used in order to improve your experience when visiting our site.For further information about cookies on this website please refer to our Cookie Policy.</p>

                            <p>For detailed information on what  cookies are and how they are used please visit to www.allaboutcookies.org.
                            </p>

                        <h1>Your Rights</h1>
                        <h3>Marketing</h3>
                        <p>We will process your data for marketing purposes only in the case where you provided us with express consent to do so or if it is within our legitimate interests. Our legitimate interests have been elaborated upon in the “How We Use Your Personal Information” section. You have the right to ask us not to process your personal information for marketing purposes. You can do this at any time by clicking on the “unsubscribe” link  in any marketing email we may send you or by emailing info&#64;indianaroma.co.uk.</p>
                       <h3>Access</h3>
                       <p>The GDPR gives you the right to </p>
                       <ul>
                        <li>access your personal information</li>
                        <li>to object to the use of your personal information for certain purposes</li>
                        <li>the right to erase and restrict the usage of your personal information</li>
                        <li>receive a machine-readable copy of your personal information</li>
                       </ul>
                       <p>In order to exercise these rights, please email info&#64;indianaroma.co.uk. We will handle your request in accordance with the law. This means there may be legal reasons why we cannot fulfil all requests</p>
                       <h1>Links to Third Party Websites</h1>
                   <p>We have integrations with third party service providers and as such there may be links that redirect you to their site. This privacy policy does not apply to any links that lead away to any other sites and we do not accept liability of the content of those sites. Please refer to the privacy policies of the respective sites before committing to submit your information over to them.</p>
                   <h1>Contact Us</h1>
                   <p>We would be glad to handle any queries and feedback regarding this policy. If you have questions about this privacy policy or the way in which Indian Aroma processes your personal information, you can contact us using the details below:</p>
                   <p>Post: Data Protection Officer, Indian Aroma, 33 Woodford Avenue, Gants Hill IG2 6UF
                    or
                    Email: info&#64;indianaroma.co.uk
                    </p>
                    </div>
                </mat-card>
            </div>
            
        </div>
  
    </div>
  </div>